<ng-container *ngIf="!isTournamentSelected" [ngTemplateOutlet]="isPhone ? smallGlassIcon : largeGlassIcon"></ng-container>


<ng-template #largeGlassIcon>
  <div
    class="link account-menu-item"
    [class.is-active]="isActive"
    [class.success]="isSuccess"
    cduxWaypoint
    [waypointKey]="INBOX_MENU_ITEM_WAYPOINT_KEY"
  >
    <i class="menu-item-icon icon-bell--light" id="inbox-menu-bell-icon" [class.ringing-animation]="hasNotifications"></i>
    <i class="notice-badge" id="inbox-menu-notification-badge" *ngIf="hasNotifications"></i>
  </div>
</ng-template>

<ng-template #smallGlassIcon>
  <div
    class="sml-inbox-menu-item"
    [class.is-active]="isActive"
    [class.success]="isSuccess"
    cduxWaypoint
    [waypointKey]="INBOX_MENU_ITEM_WAYPOINT_KEY"
  >
    <i class="menu-item-icon icon-bell--light" id="inbox-menu-bell-icon" [class.ringing-animation]="hasNotifications" style="display: block;"></i>
    <i class="notice-badge" id="inbox-menu-notification-badge-sg" *ngIf="hasNotifications" style="display: block;"></i>
  </div>
</ng-template>




