import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import {
    EventClickType,
    FeatureToggleDataService,
    FUND_ID,
    FUNDING_OPERATIONS,
    IEventClickDetailsAttribute,
    IFundingOption,
    JwtSessionService,
    TranslateService,
    WindowRefService,
} from '@cdux/ng-common';
import { CduxMediaToggleService, ModalService } from '@cdux/ng-platform/web';

import { DomUtil } from '../../../common/utils/DomUtil';
import { MenuItemsEnum } from '../../../menu-items/enums/menu-items.enum';
import { CduxSidebarContentComponent } from '../../../sidebar/cdux-sidebar-content-component.class';
import { SIDEBAR_LOADERS } from '../../../sidebar/enums/loader.enums';
import {
    ISidebarComponentProperties,
    ISidebarHeaderComponent,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from '../../../sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { IFundingDepositOptionsComponentProperties } from '../../shared/interfaces/funding-sidebar-component.interfaces';
import { FundingService } from '../../shared/services/funding.service';
import { FundingPaynearmeService } from '../../shared/services/paynearme.service';
import { IFundingComponentProperties } from '../methods/abstract-method.component';
import { FundingCreditCardMethodDepositComponent } from '../methods/creditcard/creditcard-method-deposit.component';
import { FundingEZBankMethodDepositComponent } from '../methods/ezbank/ezbank-method-deposit.component';
import { FundingEzmoneyMethodDepositComponent } from '../methods/ezmoney/ezmoney-method-deposit.component';
import { FundingMoneygramMethodDepositComponent } from '../methods/moneygram/moneygram-method-deposit.component';
import { FundingMoneygramMethodDepositFullpageComponent } from '../methods/moneygram/moneygram-method-deposit-fullpage.component';
import { FundingPaynearmeMethodDepositComponent } from '../methods/paynearme/paynearme-method-deposit.component';
import { FundingPaypalMethodDepositComponent } from '../methods/paypal/paypal-method-deposit.component';
import { CduxRouteUtil } from '../../../common/utils';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingCreditCardMethodDepositFullscreenComponent } from 'app/shared/funding/components/methods/creditcard/creditcard-method-deposit-fullscreen.component';
import { FundingEZBankMethodFullPageFundingComponent } from '../methods/ezbank/ezbank-method-deposit-fullpage.component';
import { FundingEzmoneyMethodDepositFullPageComponent } from '../methods/ezmoney/ezmoney-method-deposit-fullpage.component';
import { FundingEzmoneyMethodDepositExpressComponent } from '../methods/ezmoney/ezmoney-method-deposit-express.component';
import { FundingPaypalMethodDepositFullpageComponent } from 'app/shared/funding/components/methods/paypal/paypal-method-deposit-fullpage.component';
import { FundingPaynearmeMethodDepositFullPageComponent } from '../methods/paynearme/paynearme-method-deposit-fullpage.component';
import { GreendotATRDepositFullPageComponent } from '../methods/greendot/greendot-atr-deposit-fullpage.component';

const IS_BET_SHARE_TOKEN = 'isBetShare';
const IS_BET_PAD_TOKEN = 'isBetPad';
const INBOUND_BET_TOKEN = 'inboundBet';
const AMOUNT_TOKEN = 'amount';
const MINIMUM_AMOUNT_TOKEN = 'minimumAmount';
const OFFER_ID_TOKEN = 'offerId';
const METHOD_TO_SELECT_TOKEN = 'methodToSelect';
const AUTO_SELECT_TOKEN = 'shouldAutoSelect';
const ENABLED_IDS_TOKEN = 'enabledIds';
const FLOW_ATTRIBUTE = 'flowAttribute';

@Component({
    selector: 'cdux-funding-deposit-options',
    templateUrl: './deposit-options.component.html',
    styleUrls: [ './deposit-options.component.scss' ]
})
export class FundingDepositOptionsComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {

    @Input()
    public displayStyle: enumFundingDisplayStyle = enumFundingDisplayStyle.SIDEBAR;

    @Input()
    public selectedMethodId: FUND_ID;

    @Input()
    public enabledIDs: FUND_ID[] = null;

    @Input()
    public isSmallGlass = false;

    @Output()
    public selectedMethod: EventEmitter<ISidebarPortalComponent> = new EventEmitter<ISidebarPortalComponent>();

    @Output()
    public methodsLoaded: EventEmitter<IFundingOption[]> = new EventEmitter<IFundingOption[]>();

    @Output()
    public noMethod: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Make public for template consumption
    public enumDepositDisplayStyle = enumFundingDisplayStyle;

  /**
   *   Current Active Funding Method
   *   Getter to pass value from service to template
   */
  public get activeFundingMethod(): FUND_ID {
      return (this.isSmallGlass) ? null : this.shouldAutoSelectMethod && this._fundingService.activeFundingMethod;
  }

  /**
   *   Last Funding Amount
   *   Getter to pass value from service to template
   */
  public get lastFundingAmount(): number {
      return this._fundingService.lastFundingAmount;
  }

  /**
   *   Collection of Funding Options
   *   Getter to pass value from service to template
   */
  public fundingOptions: IFundingOption[] = [];

  // Whether we are returning from a Funding Option
  public return = false;

  // Allows us to directly set whether we should auto choose a previous method
  public shouldAutoSelectMethod = true;

  // Indicator for whether we're in a bet share context.
  public isBetShare: boolean = false;

  // Allows us to send the flow for click events
  public flowAttribute: IEventClickDetailsAttribute;

  // Inbound bet amount
  public inboundBet: string;

  // amount to suggest that the user deposits.
  public amount = 0;

  // Forced minimum amount to deposit.
  public minimumAmount: number;

  // Opt in deposit offerId
  public offerId = 0;

  // Method Selector Override. This will override the "shouldAutoSelectMethod" flag if both are set
  public methodToSelect: FUND_ID;

  // Indicator for whether we're in a bet pad.
  public isBetPad: boolean = false;

  // Private ref of native of window object
  private _window: Window;

  // Private feature toggle name for EZBANK/Mazooma service, yes "MAZOOMA" is correct
  private MAZOOMA: string = 'MAZOOMA';

  // Private feature toggle name for new EZBANK widget (F2844: PHP Elimination - EZBank)
  private EZBANK_WIDGET: string = 'EZBANK';

  // Private feature toggle name for new GreenDot ATR widget (F2907: PHP Elimination - GreenDot ATR)
  private GREENDOT_WIDGET: string = 'SHOW_TUXGD';

  private amexEnabled: boolean = false;

    private _destroy: Subject<boolean> = new Subject();

  private readonly _FEATURE_FLAG_AMEX: string = 'AMEX';

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingDepositOptionsComponentProperties = {}): ISidebarPortalComponent {
        const inputTokens: Map<any, any> = new Map();

        if (options.isBetShare) {
            inputTokens.set(IS_BET_SHARE_TOKEN, options.isBetShare);
        }

        if (options.isBetPad) {
            inputTokens.set(IS_BET_PAD_TOKEN, options.isBetPad);
        }

        if (options.betId) {
            inputTokens.set(INBOUND_BET_TOKEN, options.betId);
        }

        if (options.amount) {
            inputTokens.set(AMOUNT_TOKEN, options.amount);
        }

        if (options.minimumAmount) {
            inputTokens.set(MINIMUM_AMOUNT_TOKEN, options.minimumAmount);
        }

        if (options.offerId) {
            inputTokens.set(OFFER_ID_TOKEN, options.offerId);
        }

        if (options.methodToSelect) {
            inputTokens.set(METHOD_TO_SELECT_TOKEN, options.methodToSelect);
        }

        if (options.flowAttribute) {
            inputTokens.set(FLOW_ATTRIBUTE, options.flowAttribute);
        }

        // If we have a false value sent in, we need to preserve that value
        //  so we test for the property and not if the property is falsey
        if (options.hasOwnProperty('shouldAutoSelectMethod')) {
            inputTokens.set(AUTO_SELECT_TOKEN, options.shouldAutoSelectMethod);
        }

        if (options.enabledIds) {
            inputTokens.set(ENABLED_IDS_TOKEN, options.enabledIds);
        }

        return {
            component: FundingDepositOptionsComponent,
            properties: {
                inputs: inputTokens,
                navTarget: MenuItemsEnum.FUNDING
            }
        };
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            title: 'Deposit Funds'
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
        if (properties.inputs.has(IS_BET_SHARE_TOKEN)) {
            this.isBetShare = properties.inputs.get(IS_BET_SHARE_TOKEN);
        }

        if (properties.inputs.has(IS_BET_PAD_TOKEN)) {
            this.isBetPad = properties.inputs.get(IS_BET_PAD_TOKEN);
        }

        if (properties.inputs.has(FLOW_ATTRIBUTE)) {
            this.flowAttribute = properties.inputs.get(FLOW_ATTRIBUTE);
        }

        if (properties.inputs.has(INBOUND_BET_TOKEN)) {
            this.inboundBet = properties.inputs.get(INBOUND_BET_TOKEN);
        }

        if (properties.inputs.has(AMOUNT_TOKEN)) {
            this.amount = properties.inputs.get(AMOUNT_TOKEN);
        }

        if (properties.inputs.has(MINIMUM_AMOUNT_TOKEN)) {
            this.minimumAmount = properties.inputs.get(MINIMUM_AMOUNT_TOKEN);
        }
        if (properties.inputs.has(OFFER_ID_TOKEN)) {
            this.offerId = properties.inputs.get(OFFER_ID_TOKEN);
        }

        if (properties.inputs.has(METHOD_TO_SELECT_TOKEN)) {
            this.methodToSelect = properties.inputs.get(METHOD_TO_SELECT_TOKEN);
        }

        if (properties.inputs.has(AUTO_SELECT_TOKEN)) {
            this.shouldAutoSelectMethod = properties.inputs.get(AUTO_SELECT_TOKEN);
        }

        if (properties.inputs.has(ENABLED_IDS_TOKEN)) {
            this.enabledIDs = properties.inputs.get(ENABLED_IDS_TOKEN);
        }

        if (properties.isReturningFromChild) {
            this.return = (properties.isReturningFromChild === true);
        }
    }

    /* END CduxSidebarContentComponent
     * =============================== */

  constructor(
    private _sidebarService: SidebarService,
    private _translateService: TranslateService,
    private _fundingService: FundingService,
    private _paynearmeService: FundingPaynearmeService,
    private _modalService: ModalService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _windowRef: WindowRefService,
    private _jwtSessionService: JwtSessionService,
    private _mediaService: CduxMediaToggleService,
    private _featureToggleService: FeatureToggleDataService,
    private _eventTrackingService: EventTrackingService,
    protected router: Router,
  ) {
    super();
  }

  public ngOnInit() {
    this._fundingService.setEnabledFundingMethods(this.displayStyle);
    this.amexEnabled = this._featureToggleService.isFeatureToggleOn(this._FEATURE_FLAG_AMEX);
    if (!this.return || !this._fundingService.fundingOptions) {
        this._fundingService.clearFundingMethods();
        this._sidebarService.showLoadingOverlay(this.isBetShare ? SIDEBAR_LOADERS.BET_SHARE_SPINNER : SIDEBAR_LOADERS.SPINNING_LOADER);
    } else {
        this.fundingOptions = this._fundingService.fundingOptions;
        this._changeDetectorRef.detectChanges();
    }
    
    this._fundingService.onMethodChange.pipe(
        takeUntil(this._destroy)
    ).subscribe((fundId: FUND_ID) => {
        this.selectFundingMethod(this._fundingService.fundingOptions.find((method: IFundingOption) => method.fundId === fundId));
    })
    
    if (!this.return || !this._fundingService.fundingOptions) {
        this._fundingService.requestFundingMethodData(FUNDING_OPERATIONS.DEPOSIT, this.enabledIDs, this.amexEnabled).pipe(
            take(1)
        ).subscribe((res: IFundingOption[]) => {
            let method: IFundingOption;
            this.methodsLoaded.emit(res);
            this._sidebarService.hideLoadingOverlay(this.isBetShare ? SIDEBAR_LOADERS.BET_SHARE_SPINNER : SIDEBAR_LOADERS.SPINNING_LOADER);
            const preventLastUsedLogic: boolean = window.history.state['preventLastUsedLogic'];
            if (this.selectedMethodId) {
                // The parent component has passed back a method ID to be set as active. This could be because the user deep
                // linked to a specific method. If the method is in the list of methods we load it. Otherwise, on small glass,
                // we show options. On large glass we load the first method in the list.
                if (Array.isArray(res) && res.length) {
                    method = res.find(m => m.fundId === this.selectedMethodId)
                    if (!method) {  // Replace this check with an actual media query check
                        if (this.isSmallGlass) {
                            this.router.navigate(['deposit', 'options']);
                        } else {
                            method = res[0];
                        }
                    }
                }
                // Used to prioritize Online Banking (EzMoney) FUND-23866 for reference
            } else if (preventLastUsedLogic && this._fundingService.isOnlineBankingAvailable()) {
                if (this.isSmallGlass) {
                    this.router.navigate(['deposit', 'options']);
                } else {
                    const onlineBankingMethod: IFundingOption = this._fundingService.fundingOptions.find((option) => option.fundId === FUND_ID.EZMONEY);
                    method = onlineBankingMethod;
                }
            } else if (this._fundingService.activeFundingMethod || this.methodToSelect) {
                // We have a method chosen by default. This could be because of the previous method used to deposit or because
                // the sidebar component had a method selected as a property.
                const selectedFundId = (this.methodToSelect) ? this.methodToSelect : this._fundingService.activeFundingMethod;
                method = res.find((v) => v.fundId === selectedFundId);

                if (!method && !this.isSmallGlass && this.displayStyle !== enumFundingDisplayStyle.SIDEBAR && Array.isArray(res) && res.length) {
                    // We have no default method. Load the first in the list by default.
                    method = res[0];
                } else if (method.locked && this.isSmallGlass) {
                    // We have a default method but it is locked. We clear the method on small glass
                    // so that we don't load a disabled method by default.
                    method = null;
                }
            } else if (res.length === 1 || (this.displayStyle !== enumFundingDisplayStyle.SIDEBAR && !this.isSmallGlass)) {
                // There is no previous method or method to select. Likely a new user.
                // On large glass we choose the first method in the list as the default method.
                method = res[0];
            }

            if (method) {
                this.selectFundingMethod(method);
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) { return; }
            } else {
                this.noMethod.emit(true);
            }

            this.fundingOptions = this._fundingService.fundingOptions;
            /**
             * this 'if' conditional checking is to prevent a JS console error to occur
             * on BetPad when the underlying user has not made any deposit and click on
             * the Deposit Tab. The error is like:
             * Uncaught Error: ViewDestroyedError: Attempt to use a destroyed view: detectChanges
             *                   at viewDestroyedError (core.js:20354)
             *                   at Object.debugUpdateDirectives [as updateDirectives] (core.js:23810)
             *                   ...
             *                   at SafeSubscriber._next (deposit-options.component.ts:297)
             * The error was pre-existing before fix on DE13587 and DE13586.
             */
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }
    // I need this line so that it will update the UI before recalculating the content size.
    this._changeDetectorRef.detectChanges();
    this.communicateContentSizeChange();
  }

  public ngOnDestroy() {
      this._destroy.next();
      this._destroy.complete();
  }

    /**
     * Retrieves the Suggestion Amounts from the backend and passes them into the Funding Option Details component to be injected into the Sidenav
     */
    public selectFundingMethod(method: IFundingOption, replaceAccountInfo: boolean = false) {
        let portalComponent: ISidebarPortalComponent = null;
        let headerComponent: ISidebarHeaderComponent = null;
        const componentProperties: IFundingComponentProperties = {
            fundingMethodDetails: method,
            lastFundingAmount: this.lastFundingAmount,
            isBetShare: this.isBetShare,
            flowAttribute: this.flowAttribute,
            betId: this.inboundBet,
            amount: this.amount,
            minimumAmount: this.minimumAmount,
            offerId: this.offerId,
            amexEnabled: this.amexEnabled,
            isBetPad: this.isBetPad,
            replaceAccountInfo: replaceAccountInfo
        };

        switch (method.fundId) {
            case FUND_ID.EZMONEY:
                this._eventTrackingService.logClickEvent(EventClickType.EZ_MONEY_DEPOSIT_TILE);
                if(replaceAccountInfo && !this.isSmallGlass) {
                    this._fundingService.onReplaceFundingAccount.next();
                    break;
                }

                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    portalComponent = FundingEzmoneyMethodDepositComponent.getSidebarComponent(componentProperties);
                    headerComponent = FundingEzmoneyMethodDepositComponent.getHeaderComponent(!replaceAccountInfo ? method.accountInfo : null);
                } else if (this.displayStyle === enumFundingDisplayStyle.EXPRESS) {
                    this.selectedMethod.emit(FundingEzmoneyMethodDepositExpressComponent.getComponent(componentProperties));
                } else {
                    this.selectedMethod.emit(FundingEzmoneyMethodDepositFullPageComponent.getComponent(componentProperties));
                }

                break;
            case FUND_ID.CREDITCARD:
                this._eventTrackingService.logClickEvent(EventClickType.CREDIT_CARD_DEPOSIT_TILE);

                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    portalComponent = FundingCreditCardMethodDepositComponent.getSidebarComponent(componentProperties);
                    headerComponent = FundingCreditCardMethodDepositComponent.getHeaderComponent(!replaceAccountInfo ? method.cardTypeID : null, !replaceAccountInfo ? method.accountInfo : null, this.amexEnabled);
                } else {
                    this.selectedMethod.emit(FundingCreditCardMethodDepositFullscreenComponent.getComponent(componentProperties));
                }

                break;
            case FUND_ID.PAYPAL:
                this._eventTrackingService.logClickEvent(EventClickType.PAYPAL_DEPOSIT_TILE);
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    portalComponent = FundingPaypalMethodDepositComponent.getSidebarComponent(componentProperties);
                    headerComponent = FundingPaypalMethodDepositComponent.getHeaderComponent();
                } else {
                    this.selectedMethod.emit(FundingPaypalMethodDepositFullpageComponent.getComponent(componentProperties));
                }
                break;
            case FUND_ID.MONEYGRAM:
                this._eventTrackingService.logClickEvent(EventClickType.MONEYGRAM_DEPOSIT_TILE);
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    portalComponent = FundingMoneygramMethodDepositComponent.getSidebarComponent(componentProperties);
                    headerComponent = FundingMoneygramMethodDepositComponent.getHeaderComponent();
                } else {
                    this.selectedMethod.emit(FundingMoneygramMethodDepositFullpageComponent.getComponent(componentProperties));
                }
                break;
            case FUND_ID.PAYNEARME:
                this._eventTrackingService.logClickEvent(EventClickType.PAYNEARME_DEPOSIT_TILE);
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
                    this._paynearmeService.getPaynearmeUrl().subscribe(
                        (url: string) => {
                            this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
                            const hasUrl = url && url.length > 0;
                            if (hasUrl && !this.isBetPad && !this._mediaService.query('phone')) {
                                this._modalService.open(
                                    FundingPaynearmeMethodDepositComponent,
                                    {
                                        disableClose: true,
                                        context: {
                                            paynearmeFrameSource: url
                                        },
                                    }
                                );
                            } else {
                                portalComponent = FundingPaynearmeMethodDepositComponent.getSidebarComponent(!hasUrl, hasUrl ? url : null, hasUrl, this.isBetPad);
                                headerComponent = FundingPaynearmeMethodDepositComponent.getHeaderComponent();
                                this._sidebarService.loadComponent(
                                    portalComponent,
                                    headerComponent
                                );
                            }
                        },
                        (err) => {
                            this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
                            portalComponent = FundingPaynearmeMethodDepositComponent.getSidebarComponent(true);
                            headerComponent = FundingPaynearmeMethodDepositComponent.getHeaderComponent();
                            this._sidebarService.loadComponent(portalComponent, headerComponent);
                        });
                } else {
                    this._paynearmeService.getPaynearmeUrl().subscribe(
                        (url: string) => {
                            this.selectedMethod.emit(FundingPaynearmeMethodDepositFullPageComponent.getComponent(componentProperties, url));
                        },
                        (err) => {
                            this.selectedMethod.emit(FundingPaynearmeMethodDepositFullPageComponent.getComponent(componentProperties, null));
                        }
                    );
                }
                break;
            case FUND_ID.GREENDOTATR:
                this._eventTrackingService.logClickEvent(EventClickType.GREENDOT_DEPOSIT_TILE);
                if (this._featureToggleService.isFeatureToggleOn(this.GREENDOT_WIDGET)) {
                    const currentUrl = window.location.href.split('?');
                    let returnUrl = currentUrl[0] + CduxRouteUtil.filterGetParams(currentUrl[1], ['action', 'greendot']);
                    if (returnUrl.indexOf('?') === -1) {
                        returnUrl += '?action=greendotBarcodeReceived';
                    } else {
                        returnUrl += '&action=greendotBarcodeReceived';
                    }

                    if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                        this.router
                            .navigate(
                                ['/', 'account', 'deposit', 'greendot'],
                                {
                                    state: {'returnUrl': returnUrl}
                                }
                            )
                            .then(_ => this._sidebarService.close());
                    } else {
                        this.selectedMethod.emit(GreendotATRDepositFullPageComponent.getComponent(componentProperties));
                    }
                } else {
                    // navigate to old PHP page
                    this.generateGDCode();
                }
                break;
            case FUND_ID.MAZOOMA:
                this._eventTrackingService.logClickEvent(EventClickType.EZ_BANK_DEPOSIT_TILE);
                /* NOTES: Mazooma / EZBank
                 * EZBank is the CDI frontend branding for this deposit service
                 * MAZOOMA is the third-party vendor that processes these transactions
                 *      Fund_ID and main feature toggle are therefore "MAZOOMA"
                 * F2844 introduces a new EZBANK_WIDGET, which will launch a TUX Mazooma page/UI
                 */
                if (this._featureToggleService.isFeatureToggleOn(this.MAZOOMA)) {
                    if (this._featureToggleService.isFeatureToggleOn(this.EZBANK_WIDGET)) {
                        // launch new EZBank Widget (F2844: PHP Elimination - EZBank)
                        if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                            portalComponent = FundingEZBankMethodDepositComponent.getSidebarComponent(componentProperties);
                            headerComponent = FundingEZBankMethodDepositComponent.getHeaderComponent();
                        } else {
                            this.selectedMethod.emit(FundingEZBankMethodFullPageFundingComponent.getComponent(componentProperties));
                        }
                    } else {
                        // navigate to old PHP EZBank "onlinebanking" page
                        this.navigateToEzBank();
                    }
                    break;
                }
                break;
            default:
                return;
        }

        // Paynearme manages loading its component asynchronously. Loading it again
        // would cause redundant portal states to be saved in the sidebar history.
        const isPayNearMe: boolean = !!method && method.fundId === FUND_ID.PAYNEARME;
        if (portalComponent && !isPayNearMe) {
            /**
             * This will trigger _sidebarService.open() for a second time.
             * Any events tied to open will fire again *after* the first/prior
             * portal has initialized.
             */
            this._sidebarService.loadComponent(
                portalComponent,
                headerComponent,
                {
                    preserveError: SidebarService.OPT_PRESERVE_ERROR
                }
            ).then(() => {
                this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.DOT_LOADER);
            });
        }
    }
    private navigateToEzBank() {
        // Uses the `WindowRefService` to reference the global native window object
        this._window = this._windowRef.nativeWindow;

        const url = '/account/deposit/onlinebanking';
        const redirectURL = this._window.location.origin + url;
        this._window.location.href = redirectURL
    }

    private generateGDCode() {

        const userInfoObject = this._jwtSessionService.getUserInfo();
        // Uses the `WindowRefService` to reference the global native window object
        this._window = this._windowRef.nativeWindow;

        const said = userInfoObject['affId'];

        const camId = userInfoObject.camId;
        const firstName = userInfoObject.firstName;
        const lastName = userInfoObject.lastName;
        const email = userInfoObject.email;

        const url = '/php/GreenDot/ecache/deposit.php';
        const redirectURL = this._window.location.origin + this._window.location.pathname + '?action=greendotBarcodeReceived';

        const params = {
            accountno: camId,
            affiliateId: said,
            firstname: firstName,
            lastname: lastName,
            emailaddr: email,
            redirect: redirectURL
        };
        DomUtil.postRedirect(url, params);
    }

    /**
     * Clears out linked account information for a method
     * @param method - Method to be cleared
     */
    public replaceFundingMethod(method: IFundingOption) {
        this.selectFundingMethod(method, true);
    }


    /**
     * Checks to see if we have added translations for this funding option
     */
    public canDisplayFundingOption(method: IFundingOption) {
        return this._translateService.hasLanguage(method.fundType);
    }

    /**
     * Retrieves the Specific Tile Icon to use for the Funding Method
     */
    public getIcon(key: string, method: IFundingOption) {
        if (method.fundId === FUND_ID.CREDITCARD) {
            if (method.accountInfo) {
                switch (method[ 'cardTypeID' ]) {
                    case 1:
                        return this._translateService.translate(`${key}-visa`, method.fundType);
                    case 2:
                        return this._translateService.translate(`${key}-mc`, method.fundType);
                    case 4:
                        return this._translateService.translate(`${key}-amex`, method.fundType);
                }
            } else if (this.amexEnabled) {
                key = 'tile-icon-plus-amex';
            }
        }
        return this._translateService.translate(key, method.fundType);
    }

}
