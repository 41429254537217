import { LANG_COMMON } from './common';

export const LANG_1400_NAME = '1400';

// 4100 specific extension of the common affiliate dictionary
const LANG_1400_EXTENSION = {
  'customer-service': '1-844-OAK-BETS (1-844-625-2387)',
  'player-services-email': 'player.services@oaklawnanywhere.com',
  'registration-cellphone-alert-header': '',
  'registration-cellphone-alert-body': '',
  'registration-ssn-alert-body': '',
  'registration-player-services-fax': '1-859-223-9141',
  'registration-player-services-phone': '1-855-845-5035',
  'registration-account-exists-body': 'You may already have an OaklawnAnywhere account. You can retrieve your account information and reset your password ',
  'registration-success-header-woffer': 'Welcome to OaklawnAnywhere',
  'bet-share-registration-success-header-woffer': 'Welcome to OaklawnAnywhere',
  'primary-phone-number': '1-844-625-2387',
  'primary-phone-number-vanity': '1-844-OAK-BETS',
  'primary-phone-hyperlink': 'tel:18446252387',
  'secondary-phone-number-2': '',
  'secondary-phone-number-vanity': '',
  'facebook-url': 'https://www.facebook.com/oaklawnanywhere',
  'twitter-url': 'https://twitter.com/oaklawnanywhere',
  'instagram-url': '',
  'support-display-social-media-icons': 'true',
  'support-display-feedback-link': 'true',
  'support-display-faq-link': 'true',
  'support-display-chat-link': 'true',
  'moneygram-affiliateId': 'Oaklawnanywhere.com',
  'moneygram-receiveId': '12998',
  'affiliate-name': 'OaklawnAnywhere',
  'tournament-modal-redirect-homepage-title': 'Homepage Unavailable',
  'tournament-modal-redirect-homepage-subTitle': 'You are logged into your tournament account. To access the Oaklawn homepage, you must be in your primary account.',
  'tournament-modal-redirect-register-title': 'REGISTRATION UNAVAILABLE',
  'tournament-modal-redirect-register-subTitle': 'You are logged into your tournament account. To register for a tournament, you must be in your primary account.'
};

export const LANG_1400_TRANS = Object.assign({}, LANG_COMMON, LANG_1400_EXTENSION);
