import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AbstractContentCardComponent } from '../abstract-content-card.component';
import * as braze from '@braze/web-sdk';

@Component({
    selector: 'cdux-classic-card',
    templateUrl: './classic-card.component.html',
    styleUrls: ['./classic-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassicCardComponent extends AbstractContentCardComponent {
    @Input() override set card(value: braze.ClassicCard) {
        super.card = value;
    }

    override get card(): braze.ClassicCard {
        return this._card as braze.ClassicCard;
    }

    constructor() {
        super();
    }
}
