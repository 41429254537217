<!---------------------------------------------------->
<!-- IF using Full Page Withdraw, display following -->
<!---------------------------------------------------->
<ng-container *ngIf="fpwEnabled">
  <header class="funding-content_header">
    <!-- back arrow hidden on desktop -->
    <i class="icon--nav-back-white" (cduxTap)="navigateToOptions()"></i>
    <i *ngIf="isSmallGlass" class="icon--close-light" (cduxTap)="close()" id="paypal-withdraw-close-btn"></i>
    <h1 id="fpw-paypal-title">PayPal</h1>
    <div class="funding-type-icon">
      <i class="icon-paypal" id="fpw-paypal-icon"></i>
    </div>
  </header>
  <form [formGroup]="form" *ngIf="form">
    <!--<div class="enter-amount-label">Enter Amount</div>-->
    <div class="funding-content_input-block">
      <cdux-funding-input-amount
        id="-fpw-withdraw-amount"
        formControlName="amount"
        [inputType]="OPERATION"
        [fundType]="fundingMethodDetails.fundType"
        [min]="1"
        [displayMin]="true"
        [max]="getMaxWithdrawAmount()"
        [displayMax]="true"
        [phoneEnabled]="phoneEnabled"
        [displayStyle]="enumWithdrawDisplayStyle.FULL_PAGE">
      </cdux-funding-input-amount>
    </div>

    <!-- Pending and Available Amount -->
    <cdux-funding-pending-balances [withdrawBalance]="withdrawBalance"></cdux-funding-pending-balances>

    <!-- Detail block -->
    <div class="funding-content_details-block" *ngIf="fundingMethodDetails.accountInfo; else noAccount">
      <p id="fpw-paypal-account-lbl">PayPal Account</p>
      <p class="email" id="fpw-paypal-account-email">{{fundingMethodDetails.accountInfo.toLowerCase()}}</p>

      <div class="amount--deposit">
        <span class="label" id="fpw-paypal-detail-amount-lbl">Withdrawal:</span>
        <span class="amount" id="fpw-paypal-detail-amount">{{form.get('amount').value | currency:'USD':'symbol-narrow':'1.2-2'}}</span>
      </div>
      <div class="amount--fee">
        <span class="label" id="fpw-paypal-detail-fee-lbl">PayPal Fee:</span>
        <span class="amount" id="fpw-paypal-detail-fee">{{fundingMethodDetails.flatFee | currency:'USD':'symbol-narrow':'1.2-2'}}</span>
      </div>
      <div class="total">
        <span class="label" id="fpw-paypal-detail-total-lbl">Total:</span>
        <span class="amount" id="fpw-paypal-detail-total">{{(form.get('amount').value + fundingMethodDetails.flatFee) | currency:'USD':'symbol-narrow':'1.2-2'}}</span>
      </div>
      <div class="paypal--btn large" (click)="!pendingWithdraw && onWithdraw(true)" [class.disabled]="form.invalid || pendingWithdraw" id="fpw-paypal-detail-submit-btn"><i class="icon-paypal-btn"></i></div>
    </div>
  </form>

  <!-- Error section -->
  <!-- No PayPal deposit made yet, show unable to withdraw message -->
  <ng-template #noAccount>
    <div class="funding-content_details-block">
      <h3 class="center-text margin-top-20">METHOD NOT ON FILE</h3>
      <p id="fpw-paypal-no-account-error" class="p-centered-normal">
        It looks like we don't have an account on file for
        this method. Before you can withdraw funds to
        a PayPal account, it must first be used to
        complete at least one deposit.
      </p>
    </div>
  </ng-template>

</ng-container>

<!----------------------------------------------->
<!-- IF still using sidebar, display following -->
<!----------------------------------------------->
<ng-container *ngIf="!fpwEnabled">
    <ng-container *ngIf="!displayActionItem else actionItem">
      <form class="withdraw-form-wrapper paypal" [formGroup]="form" *ngIf="form" [class.dark]="lockout">

        <div class="ts-message error" *ngIf="lockout">
          <p>We were unable to process your withdrawal. Your account may be setup incorrectly.</p>
          <p>Please contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</p>
        </div>

        <div *ngIf="!lockout">
          <cdux-funding-input-amount id="withdrawAmount" formControlName="amount"
             [inputType]="OPERATION"
             [fundType]="fundingMethodDetails.fundType"
             [min]="0"
             [displayMin]="false"
             [max]="getMaxWithdrawAmount()"
             [phoneEnabled]="phoneEnabled"
             [displayMax]="false">
          </cdux-funding-input-amount>

          <cdux-funding-pending-balances [withdrawBalance]="withdrawBalance"></cdux-funding-pending-balances>

          <div class="form-account-info-wrapper">
            <div class="account-header">
              {{ 'withdraw-account-header' | cduxTranslate:fundingMethodDetails.fundType }}
            </div>
            <div class="account-info">
              {{ fundingMethodDetails.accountInfo }}
            </div>
          </div>

          <div class="ts-message error" *ngIf="errorCode !== null">{{ '' + errorCode | cduxTranslate:'errorcodes':true }}</div>
          <div class="ts-message success" *ngIf="successfulDeposit && !errorCode">{{ successMessage }}</div>

          <cdux-funding-transaction-summary
            transactionName="Amount"
            [amount]="form.get('amount').value"
            feeName="PayPal Fee"
            [feeValue]="fundingMethodDetails.flatFee">
          </cdux-funding-transaction-summary>

          <div class="cdux-button-wrapper">
            <button #withdrawButton id="withDrawButton" (click)="!pendingWithdraw && onWithdraw()" [disabled]="form.invalid || form.get('amount').value <= 0 || pendingWithdraw">
              Withdraw
            </button>
          </div>
        </div>

      </form>
    </ng-container>
  <ng-template #actionItem>
    <div class="onlineBankingActionMessage">
      Please note, there is a ${{fundingMethodDetails?.flatFee}} fee to withdraw using PayPal. For a no fees method, consider choosing Online Banking as a withdrawal option.
    </div>
    <div class="onlineBankingActionButtonContainer">
      <button id="goToOnlineBankingButton" class="button-outline--secondary" (click)="goToOnlineBanking()">TRY FAST & FREE ONLINE BANKING</button>
      <br/>
      <button id="continueButton"  (click)="continueToForm()">CONTINUE TO PAYPAL WITHDRAWAL</button>
    </div>
  </ng-template>
</ng-container>
