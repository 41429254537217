import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AbstractContentCardComponent } from '../abstract-content-card.component';
import * as braze from '@braze/web-sdk';

@Component({
    selector: 'cdux-captioned-image-card',
    templateUrl: './captioned-image-card.component.html',
    styleUrls: ['./captioned-image-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptionedImageCardComponent extends AbstractContentCardComponent {
    @Input() override set card(value: braze.CaptionedImage) {
        super.card = value;
    }

    override get card(): braze.CaptionedImage {
        return this._card as braze.CaptionedImage;
    }

    constructor() {
        super();
    }
}
