import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    Bet,
    BetAmountType,
    ConfigurationDataService,
    enumConfigurationStacks,
    enumPoolType,
    enumRaceStatus,
    enumTxRepoWagerType,
    EventClickAttributeType,
    EventClickType,
    FormatBetAmountPipe,
    JwtSessionService,
    ILabelValuePair,
    PoolType,
    ProgramEntry,
    TermTypes,
    ToteDataService,
    TrackService,
    TracksDataService,
    TranslateService,
    enumBetModifier,
    WagerCalculatorService,
    WagerState,
    WagerStateUtil,
    WagerValidationService,
    WagerValidationCodes,
    convertFractionToNumeric,
    PreferencesService,
} from '@cdux/ng-common';

import { LOGIN_REDIRECT_PATH } from 'app/app.routing.constants';
import { BetsBusinessService } from 'app/shared/bet-slip/services/bets.business.service';
import { DisplayModeEnum } from 'app/shared/common/enums/display-mode.enum';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { BetTypesRequestHandler } from 'app/shared/betpad/classes/bet-types-request-handler.class';
import { EntriesManager } from 'app/shared/program/classes/entries-manager.class';
import { EntryUpdateHandler } from 'app/shared/program/classes/entry-update-handler.class';
import { BetActionsEnum } from 'app/shared/bet-slip/enums/bet-actions.enum';
import { BetSlipErrorsService } from 'app/shared/bet-slip/services/bet-slip-errors.service';
import { AbstractWageringViewEventComponent } from 'app/shared/wager-views/components/abstract-wagering-view-event.component';
import { WagerEventTypeEnum } from 'app/shared/wager-views/enums/wager-event-type.enum';
import { WagerManager } from 'app/wagering/views/classes/wager-manager.class';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { BET_ERROR_CODES } from 'app/shared/bet-slip/enums/bet-error-codes.enum';
import { PreferencesComponent } from 'app/shared/my-account/preferences/preferences.component';
import {
    TournamentsSessionService
} from '../../../tournaments-session/services/touranments-session.service';


@Component({
    selector: 'cdux-quick-pick-sidebar',
    templateUrl: './quick-pick-sidebar.component.html',
    styleUrls: [ './quick-pick-sidebar.component.scss' ]
})
export class QuickPickSidebarComponent extends AbstractWageringViewEventComponent implements OnDestroy {
    public readonly TERM_TYPE = TermTypes.QUICK_PICK_TERMS_AND_CONDITIONS;
    public readonly WAGER_ERRORS_LANG = 'wager-errors';

    public readonly DisplayModeEnum = DisplayModeEnum;

    @Input()
    public displayMode: DisplayModeEnum = DisplayModeEnum.LARGE;

    @Input()
    public set raceStatus(raceStatus: enumRaceStatus) {
        this._raceStatus = raceStatus;
        this.isWagerableRace = TrackService.isWagerableRace(raceStatus);
    }
    public get raceStatus(): enumRaceStatus {
        return this._raceStatus;
    }
    private _raceStatus: enumRaceStatus;


    @Input()
    public set wagerState(wagerState: WagerState) {
        this._wagerManager.selectTrackFromList([wagerState.basicTrack]);
        this._wagerManager.updateRaceNav(wagerState.basicTrack);
        this._betTypesRequestHandler.updateBetTypes(wagerState.basicTrack);
        this._entriesManager.updateRaceNavigation(wagerState.basicTrack);
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }
    private _wagerState: WagerState;


    @Input()
    public poolCode: enumPoolType = enumPoolType.TRIFECTA; // default type
    public poolType: PoolType = PoolType.getPoolTypeByCode(this.poolCode);


    @Output()
    public opened = new EventEmitter<void>();

    @Output()
    public closed = new EventEmitter<void>();

    @HostBinding('attr.hidden')
    public get isQuickPickDisabled(): true | null {
        return !this._isQuickPickAllowed || this._isQuickPickHidden ? true : null;
    }
    private _isQuickPickAllowed = false;
    private _isQuickPickHidden = false;


    public runnerCountOptions: ILabelValuePair[] = [];
    public set selectedRunnerCount(runnerCountOption: ILabelValuePair) {
        this._runnerCountOption = runnerCountOption;
        this._wagerManager.resetEntries();
    }
    public get selectedRunnerCount(): ILabelValuePair {
        return this._runnerCountOption;
    }
    private _runnerCountOption: ILabelValuePair;


    public get hasFavorite(): boolean {
        return this._numberOfFavorites > 0 &&
            this._numberOfFavorites < this.poolType.selectionCount;
    }
    public get mustIncludeFavorite(): boolean {
        return this.hasFavorite &&
            this.selectedRunnerCount?.value > (this._numberOfUnscratched - this._numberOfFavorites);
    }
    public set includeFavorite(includeFavorite: boolean) {
        this._includeFavorite = includeFavorite;
    }
    public get includeFavorite(): boolean {
        return this.hasFavorite && (this.mustIncludeFavorite || this._includeFavorite);
    }
    private _includeFavorite = true;
    private _numberOfFavorites = 0;
    private _numberOfUnscratched = 0;


    public betAmountOptions: ILabelValuePair[];
    public set selectedBetAmount(betAmount: ILabelValuePair) {
        this._betAmountOption = this.betAmountOptions?.find(
            (ba) => ba.value === betAmount.value
        );
        if (this._betAmountOption) {
            this._wagerManager.updateBetAmount({
                value: this._betAmountOption.value,
                type: BetAmountType.PROVIDED
            });
        }
    }
    public get selectedBetAmount(): ILabelValuePair {
        return this._betAmountOption;
    }
    private _betAmountOption: ILabelValuePair;


    public wagerAmount: number = 0;
    public wagerError: string = null;
    public allEntries: ProgramEntry[] = [];
    public randomEntries: ProgramEntry[] = [];

    public isExpanded: boolean = false;
    public isLoading: boolean = false;
    public isLoggedIn: boolean = false;
    public isSubmitting: boolean = false;
    public isWagerableRace: boolean = false;
    public isPoolAvailable: boolean = false;
    public isWagerValid: boolean = false;

    private _currentRaceDate: string;

    private _destroy = new Subject<void>();
    private _wagerManager: WagerManager;
    private _betTypesRequestHandler: BetTypesRequestHandler;
    private _entriesManager: EntriesManager;
    private _entryUpdateHandler: EntryUpdateHandler;

    constructor (
        private _betSlipErrorService: BetSlipErrorsService,
        private _betsService: BetsBusinessService,
        private _configurationService: ConfigurationDataService,
        private _changeDetector: ChangeDetectorRef,
        private _environment: ENVIRONMENT,
        private _eventTrackingService: EventTrackingService,
        private _formatBetAmount: FormatBetAmountPipe,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _toteDataService: ToteDataService,
        private _tracksDataService: TracksDataService,
        private _translateService: TranslateService,
        private _wagerCalculator: WagerCalculatorService,
        private _wageringUtilService: WageringUtilBusinessService,
        private _wagerValidationService: WagerValidationService,
        private _preferencesService: PreferencesService,
        private _tournamentSessionService: TournamentsSessionService
    ) {
        super();

        this._toteDataService.currentRaceDate(true).pipe(
            takeUntil(this._destroy)
        ).subscribe((currentRaceDate) =>
            this._currentRaceDate = currentRaceDate
        );

        this._wagerManager = new WagerManager();
        this._wagerManager.listen().subscribe((wagerState) => {
            this._wagerState = wagerState;
            const selectionCount = this.selectedRunnerCount?.value || this.poolType.selectionCount;
            const selectedCount = wagerState?.bettingInterests?.reduce((p, c) => p += c?.length, 0);
            this.wagerAmount = selectedCount >= selectionCount ?
                this._wagerCalculator.calculate(WagerStateUtil.calculableFromWagerState(wagerState)) : 0;
            const validation = selectedCount >= selectionCount ?
                this._wagerValidationService.validate(WagerStateUtil.validatableWagerFromWagerState(wagerState)) : null;
            switch (validation) {
                case WagerValidationCodes.VALID:
                    this.wagerError = null;
                    this.isWagerValid = true;
                    break
                case WagerValidationCodes.MINIMUM_VALUE_NOT_MET:
                    this.wagerError = this._translateService.translate(BET_ERROR_CODES.MIN_NOT_MET, this.WAGER_ERRORS_LANG);
                    this.isWagerValid = false;
                    break;
                case WagerValidationCodes.MAXIMUM_VALUE_EXCEEDED:
                    this.wagerError = this._translateService.translate(BET_ERROR_CODES.MAX_EXCEEDED, this.WAGER_ERRORS_LANG);
                    this.isWagerValid = false;
                    break;
                default:
                    this.wagerError = this._translateService.translate(BET_ERROR_CODES.UNKNOWN_ERROR, this.WAGER_ERRORS_LANG);
                    this.isWagerValid = false;
                    break;
            }
            this._changeDetector.markForCheck();
        });

        this._betTypesRequestHandler = new BetTypesRequestHandler(this._tracksDataService);
        this._betTypesRequestHandler.listen().subscribe((betTypes) => {
            this._wagerManager.selectBetTypeFromList(betTypes);
            const betType = betTypes.find((bt) => bt.poolType.code === this.poolCode);
            if (betType) {
                this.isPoolAvailable = true;
                this.betAmountOptions = (betType.betAmounts || []).map((v) => ({
                    label: this._formatBetAmount.transform(v.value), value: v.value
                }));

                const selectedBetAmount = this.betAmountOptions.find((bao) =>
                    bao.value === this.selectedBetAmount?.value
                ) || this.betAmountOptions[0];
                if (!this.selectedBetAmount || this.selectedBetAmount.value !== selectedBetAmount?.value) {
                    this.selectedBetAmount = selectedBetAmount;
                }

                this._wagerManager.updateBetType(betType);
                this._wagerManager.updateBetModifier(enumBetModifier.BOX);
                this._wagerManager.updateBetAmount({
                    value: this.selectedBetAmount?.value || 0,
                    type: BetAmountType.PROVIDED
                });
            } else {
                this.isPoolAvailable = false;
            }
        });

        this._entriesManager = new EntriesManager(
            this._tracksDataService,
            this._wagerManager.listen().pipe(
                map(wager => wager?.betNav),
                filter(betNav => !!betNav)
            )
        );
        this._entriesManager.listen().subscribe((entryState) => {
            this.allEntries = entryState?.programEntries[0] || [];
            this._numberOfFavorites = this._findMLFavorites(this.allEntries).length;
            this._numberOfUnscratched = this.allEntries.reduce((p, c) => c.Scratched ? p : p + 1, 0);

            this.runnerCountOptions = this.allEntries
                .filter((e) => !e.Scratched)
                .map((_e, i) => (
                    { label: (i + 1) + ' Runners', value: i + 1 }
                ))
                .filter((opt) =>
                    // remove options below pool selection count
                    opt.value >= (this.poolType.selectionCount)
                );

            const selectedRunnerCount = this.runnerCountOptions.find(
                (rco) => rco.value === this.selectedRunnerCount?.value
            ) || this.runnerCountOptions[0];
            if (this.selectedRunnerCount?.value !== selectedRunnerCount?.value) {
                this.selectedRunnerCount = selectedRunnerCount;
            }
        });

        this._entryUpdateHandler = new EntryUpdateHandler(
            this._wagerManager.listen().pipe(
                filter(wager => !!wager?.betNav)
            ),
            this._entriesManager.listen()
        );
        this._entryUpdateHandler.listen().subscribe((entryUpdate) => {
            const selectionCount = this.selectedRunnerCount?.value || this.poolType.selectionCount;
            if (entryUpdate.bettingInterests.reduce((p, c) => p += c?.length, 0) < selectionCount &&
                entryUpdate.updatedEntries.reduce((p, c) => p += c?.length, 0) >= selectionCount) {
                this.randomEntries = this._getRandomEntries(this.allEntries, selectionCount);
                this._wagerManager.updateEntries([{
                    selected: true,
                    leg: 0,
                    entries: this.randomEntries.map((pe) =>
                        this._wageringUtilService.toSelectedEntry(pe)
                    )
                }]);
            }
        });

        this._preferencesService.watchFlag(PreferencesComponent.DISABLE_QUICK_PICK_KEY).pipe(
            takeUntil(this._destroy)
        ).subscribe((value) => {
            this._isQuickPickHidden = !!value;
            this._changeDetector.markForCheck();
        });

        const quickPickAllowedObservable = this._configurationService.getConfiguration(enumConfigurationStacks.TUX, PreferencesComponent.QUICK_PICK_BANNED_STATES).pipe(
            map((config) => config?.[PreferencesComponent.QUICK_PICK_BANNED_STATES]?.split(',').map(s => s.trim().toUpperCase())),
            switchMap((bannedStates) =>
                this._sessionService.onAuthenticationChange.pipe(
                    startWith(this._sessionService.isLoggedIn()),
                    map((login) => login && this._sessionService.getUserInfo()?.state?.trim().toUpperCase() || null),
                    map((state) => !!state && !!bannedStates && !bannedStates.includes(state)) // state and config required
                )
            )
        );

        // combine quick pick allowed with tournament selection
        combineLatest([
            quickPickAllowedObservable,
            this._tournamentSessionService.onTournamentSelection.pipe(
                distinctUntilChanged()
            )
        ]).pipe(
            takeUntil(this._destroy)
        ).subscribe(([isQuickPickAllowed, tournamentAccount]) => {
            this._isQuickPickAllowed = isQuickPickAllowed && !tournamentAccount?.tournament?.idTournament;
            this._changeDetector.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        if (this.isExpanded) {
            this.close();
        }

        this._destroy.next();
        this._wagerManager?.kill();
        this._betTypesRequestHandler?.kill();
        this._entriesManager?.kill();
        this._entryUpdateHandler?.kill();
    }

    public canSubmit(): boolean {
        return this.isWagerableRace && this.isPoolAvailable && this.isWagerValid;
    }

    public open(): void {
        if (!this.isExpanded) {
            this.isExpanded = true;
            this.selectedBetAmount = this.betAmountOptions[0] || null;
            this.selectedRunnerCount = this.runnerCountOptions[0] || null;
            this.includeFavorite = true;
            this.opened.next();
            this._logClickEvent(EventClickType.QUICK_PICK_OPEN);
        }
    }

    public close(): void {
        if (this.isExpanded) {
            this._close();
            this._logClickEvent(EventClickType.QUICK_PICK_CLOSE);
        }
    }

    public toggle(): void {
        if (this.isExpanded) {
            this.close();
        } else {
            this.open();
        }
    }

    public submit(): void {
        if (this.isSubmitting || !this.canSubmit()) {
            return;
        } else if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate([ LOGIN_REDIRECT_PATH ]);
        } else {
            this.isSubmitting = true;
            this.wagerError = null;
        }

        this._logClickEvent(EventClickType.QUICK_PICK_BET, [
            { attrId: EventClickAttributeType.QUICK_PICK_BET_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode },
            { attrId: EventClickAttributeType.QUICK_PICK_BET_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType },
            { attrId: EventClickAttributeType.QUICK_PICK_BET_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum },
            { attrId: EventClickAttributeType.QUICK_PICK_BET_RACE_DATE, data: this._currentRaceDate },
            { attrId: EventClickAttributeType.QUICK_PICK_BET_BASE_WAGER, data: +this.selectedBetAmount?.value },
            { attrId: EventClickAttributeType.QUICK_PICK_BET_RUNNER_COUNT, data: +this.selectedRunnerCount?.value },
            { attrId: EventClickAttributeType.QUICK_PICK_BET_TOTAL_WAGER, data: +this.wagerAmount }
        ]);

        this._betsService.submitWager(
            this._getSubmittableWager(this.wagerState),
            this.wagerAmount.toFixed(2)
        ).subscribe((wagerResult) => {
            this.isSubmitting = false;
            if (wagerResult?.success) {
                this._close();
                this._logClickEvent(EventClickType.QUICK_PICK_SUBMISSION, [
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_BRIS_CODE, data: wagerResult.track },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_TRACK_TYPE, data: wagerResult.trackType },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_RACE_NUMBER, data: wagerResult.race },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_RACE_DATE, data: this._currentRaceDate },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_BASE_WAGER, data: +wagerResult.amount },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_RUNNER_COUNT, data: +this.selectedRunnerCount?.value },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_TOTAL_WAGER, data: +wagerResult.cost },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_BET_TYPE, data: wagerResult.betType },
                    { attrId: EventClickAttributeType.QUICK_PICK_PLACED_CONFIRMATION_ID, data: wagerResult.confirmationId },
                ], true);
                this._sendWagerSuccessEvent(BetActionsEnum.SUBMIT_BET);
            } else {
                switch (wagerResult?.message) {
                    case BET_ERROR_CODES.INSUFFICIENT_FUNDS:
                        this.wagerError = this._translateService.translate(BET_ERROR_CODES.INSUFFICIENT_FUNDS_NODEPOSIT, this.WAGER_ERRORS_LANG);
                        break;
                    case BET_ERROR_CODES.RACE_CLOSED:
                        this.wagerError = this._translateService.translate(BET_ERROR_CODES.RACE_CLOSED, this.WAGER_ERRORS_LANG);
                        break;
                    case BET_ERROR_CODES.RESTRICT_PHONE_ONLY:
                        this.wagerError = this._translateService.translate(
                            BET_ERROR_CODES.RESTRICT_PHONE_ONLY,
                            this.WAGER_ERRORS_LANG,
                            TranslateService.OPT_FALLBACK_DISABLE,
                            BetSlipErrorsService.stateNamesMap[this._sessionService.getUserInfo().state],
                            this._translateService.translate('primary-phone-number-vanity', this._environment.affiliateId.toString())
                        );
                        break;
                    case BET_ERROR_CODES.SCRATCH:
                        this.wagerError = this._translateService.translate(BET_ERROR_CODES.SCRATCH, this.WAGER_ERRORS_LANG);
                        break;
                    default:
                        this.wagerError = this._translateService.translate(BET_ERROR_CODES.UNKNOWN_ERROR, this.WAGER_ERRORS_LANG);
                        break;
                }
                this._sendWagerFailureEvent(wagerResult?.wagerId, BetActionsEnum.SUBMIT_BET);
            }
        });
    }

    public refresh(): void {
        if (this.isLoading) {
            return;
        } else {
            this.isLoading = true;
            this._changeDetector.markForCheck();
        }

        setTimeout(() => {
            this.isLoading = false;
            this._wagerManager.resetEntries();

            this._changeDetector.markForCheck();
        }, 550); // delay for refresh icon animation

        this._logClickEvent(EventClickType.QUICK_PICK_REFRESH);
    }

    public setIncludeFavorite(includeFavorite: boolean): void {
        this.includeFavorite = includeFavorite;
        this._wagerManager.resetEntries();
        this._logClickEvent(EventClickType.QUICK_PICK_USE_FAV);
    }

    public getDigitsInfo(value: number | string): string {
        return +value % 1 ? '1.2-2' : '1.0-0';
    }

    public getSaddleClothClass(entry: ProgramEntry) {
        return 'saddle-cloth ' + this._wageringUtilService.getSaddleClothClass(
            this.wagerState?.basicTrack?.TrackType, entry.BettingInterest
        );
    }

    private _close() {
        // remove all entries before close
        this._wagerManager.resetEntries();
        this.isExpanded = false;
        this.closed.next();
    }

    private _logClickEvent(event: EventClickType, attributes?: any[], flush?: boolean) {
        if (attributes?.length > 0) {
            const ts = Date.now(); // apply same timestamp to attributes
            attributes?.forEach(a => a.timestamp || (a.timestamp = ts));
            this._eventTrackingService.logClickEvent(event, attributes);
        } else {
            this._eventTrackingService.logClickEvent(event);
        }
        if (flush) {
            this._eventTrackingService.flushQueue();
        }
    }

    private _getRandomEntries(entries: ProgramEntry[], numberToSelect: number): ProgramEntry[] {
        // filter scratches or duplicate interests
        entries = entries.filter((entry, index) =>
            entry && !entry.Scratched && index === entries.findIndex((e) =>
                e && !e.Scratched && e.BettingInterest === entry.BettingInterest
            )
        );

        // randomize entries by swapping lower indexes
        for (let i = entries.length - 1; i > 0; i--) {
            this._swapEntries(entries, i, Math.floor(Math.random() * i));
        }

        if (this.hasFavorite) { // swap ML favorites with start or end of array
            this._findMLFavorites(entries).forEach((e, i) => this._swapEntries(
                entries, entries.indexOf(e), this.includeFavorite ? i : entries.length - 1 - i
            ));
        }

        // slice the requested number of entries
        return entries.slice(0, numberToSelect);
    }

    private _findMLFavorites(entries: ProgramEntry[]): ProgramEntry[] {
        // find lowest odds regardless of scratched status
        const favoriteOdds = entries.reduce<number>((p, c) => Math.min(
            convertFractionToNumeric(c.MorningLineOdds), p
        ), 99);

        // find unscratched entries matching favorite odds
        return entries.filter((e) => !e.Scratched &&
            convertFractionToNumeric(e.MorningLineOdds) === favoriteOdds
        );
    }

    private _getSubmittableWager(wagerState: WagerState): Bet {
        const submittableBet = Bet.fromWagerState(wagerState);
        submittableBet.txRepoWagerType = enumTxRepoWagerType.QUICK_PICK;
        return submittableBet;
    }

    private _swapEntries(entries: ProgramEntry[], i: number, j: number): void {
        const temp = entries[j];
        entries[j] = entries[i];
        entries[i] = temp;
    }

    protected _sendWagerSuccessEvent(action: BetActionsEnum) {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SUBMISSION_SUCCESS,
            message: 'classic-and-tv-success-header'
        });
    }

    protected _sendWagerFailureEvent(betId?: string, action?: BetActionsEnum) {
        const error = this._betSlipErrorService.getError(betId);
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SUBMISSION_ERROR,
            message: error ? error.errorString : 'Sorry, your bet cannot be placed.',
            data: error
        });
    }
}
