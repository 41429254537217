import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { BrazeUtils } from '@cdux/ts-domain-braze';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CduxRouteUtil } from 'app/shared/common';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { FavEventType } from 'app/account/favorites/favorites-event-interface';
import * as braze from '@braze/web-sdk';

// Interface to represent a processed card with its associated template
interface ProcessedCard {
  card: braze.Card;
  template: TemplateRef<any> | null;
}

@Component({
    selector: 'cdux-inbox-feed',
    templateUrl: './inbox-feed.component.html',
    styleUrls: ['./inbox-feed.component.scss']
})
export class InboxFeedComponent implements OnInit, OnDestroy, AfterViewInit {
    // used for visibility tracking
    @ViewChild('inboxFeedContainer') inboxFeedContainer: ElementRef;
    scrollContainer: HTMLElement;

    // Reference to the image-only card template
    @ViewChild('imageOnlyCardTemplate', {static: true}) imageOnlyCardTemplateRef!: TemplateRef<any>;

    // Reference to the classic card template
    @ViewChild('classicCardTemplate', {static: true}) classicCardTemplateRef!: TemplateRef<any>;

    // Reference to the captioned image card template
    @ViewChild('captionedImageCardTemplate', {static: true}) captionedImageCardTemplateRef!: TemplateRef<any>;

    private _subscriptions = new Subscription();
    private cardsSubject = new BehaviorSubject<ProcessedCard[]>([]);
    cards$: Observable<ProcessedCard[]> = this.cardsSubject.asObservable();

    public i: number;

    public offersLink: string;

    constructor
    (@Inject(BrazeUtils) private _brazeUtils: BrazeUtils,
    private _elementRef: ElementRef,
    private _accountBubbleNotificationService: AccountBubbleNotificationService) {}

    ngOnInit(): void {
        this._loadCards();
        this.offersLink = CduxRouteUtil.getBaseHref(true) + 'offers';
    }

    ngAfterViewInit(): void {
        const observer = new IntersectionObserver(
            () => {},
            { threshold: 0.5 } // Trigger when 50% of the element is visible
        );
        observer.observe(this._elementRef.nativeElement);
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    private _loadCards(): void {
        this._subscribeToCardUpdates();
        // On subsequent inits, we refresh the cards
        this.refreshContentCards();

    }

    private _subscribeToCardUpdates(): void {
        this.cards$ = this._brazeUtils.getContentCards().pipe(
            map((contentCards: braze.ContentCards) => contentCards?.cards || []),
            map(this._processCards.bind(this)),
            map(this._sortCards),
            map(processedCards => processedCards as ProcessedCard[]),
            catchError((error: any) => {
                console.error('Error loading content cards:', error);
                return of([]);
            })
        );

        // Subscribe to latest cards and update UI
        this._subscriptions.add(
            this.cards$.subscribe((processedCards: ProcessedCard[]) => {
                // filter out dismissed cards:
                processedCards = processedCards.filter(pc => !pc.card.extras.hasOwnProperty('isDismissed'));
                this.cardsSubject.next(processedCards);
            })
        );
    }

    private _processCards(cards: braze.Card[]): ProcessedCard[] {
        // Need to filter out deleted (dismissed) cards:
        cards = cards.filter(cd => !cd.extras.hasOwnProperty('isDismissed')); // Remove card that has marked isDismissed
        return cards.map(card => ({
            card: card,
            template: this.handleCardView(card)
        }));
    }

    private _sortCards(processedCards: ProcessedCard[]): ProcessedCard[] {
        // Sort cards: pinned cards first, then by creation time (newest first)
        return processedCards.sort((a, b) => {
            if (a.card.pinned && !b.card.pinned) return -1;
            if (!a.card.pinned && b.card.pinned) return 1;
            return b.card?.updated?.getTime() - a.card?.updated?.getTime();
        });
    }

    /**
     * Track function for ngFor to optimize rendering
     * @param index - Index of the current item
     * @param processedCard - The current ProcessedCard
     * @returns The unique identifier for the card
     */
    public trackByCardId(index: number, processedCard: ProcessedCard): string {
        return processedCard.card?.id;
    }

    /**
     * Determine which template to use for a given card
     * @param card - The Braze Card to process
     * @returns The appropriate TemplateRef for the card, or null if no match
     */
    public handleCardView(card: braze.Card): TemplateRef<any> | null {
        if (card instanceof braze.ImageOnly) {
            return this.imageOnlyCardTemplateRef;
        }
        if (card instanceof braze.ClassicCard) {
            return this.classicCardTemplateRef;
        }
        if (card instanceof braze.CaptionedImage) {
            return this.captionedImageCardTemplateRef;
        }
        return null;
    }

    public deleteAllContentCards(): void {
        const currentCards = this.cardsSubject.value;

        // Log dismissal for all cards
        // Remove only those dismissible.
        const unDismissibles: ProcessedCard[] = [];
        currentCards.forEach(processedCard => {
            if (!processedCard.card.pinned) {
                processedCard.card.extras.isDismissed ='true';
                this._brazeUtils.dismissCard(processedCard.card);
            } else {
                unDismissibles.push(processedCard);
            }
        });

        // Clear the local cardsSubject and cards$
        this.cardsSubject.next(unDismissibles);
        this.cards$ = of(unDismissibles);

        // Send a message to inbox-menu-item to indicate All Cards deleted
        this._accountBubbleNotificationService.dispatch({
                type: FavEventType.INBOX_CONTENT_CARD_DELETE_ALL,
                payload: null
            }
        );
    }

    public onVisibilityChange(isVisible: boolean, card: any) {
        if (isVisible) {
            this._brazeUtils.logCardImpression([card]);
        }
    }

    public refreshContentCards(): void {
        console.error('Refreshing content cards');
        this._brazeUtils.refreshContentCards()
            .then(() => {
                console.error('Content cards refreshed successfully');
                // No need to call _loadCards() here as we're already subscribed to updates
            })
            .catch((error) => {
                console.error('Failed to refresh content cards:', error);
            });
    }
}
