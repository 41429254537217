<div class="program-container">
    <div class="leg-bar-container" *ngIf="legInfo?.legsCounter.length > 1 && isWagerableRace">
        <ul class="entry-leg-bar" [ngClass]="checkioClass" [ngClass]="sectionClass" [class.is-compact]="isCompact"  [class.no-pointer]="!legInfo?.multiRace" [class.has-silk]="!isBasicClass && isJockeySilksEnabled && areSilksAvailable">
            <div class="entry_col_num"><li ><!--an empty first li is necessary for spacing--></li></div>
            <div class="entry_col_checkio">
              <li #thisLeg
                  *ngFor="let leg of  legInfo?.legsCounter; let legNumber = index;"
                  id="ProgramLegBarTab-{{legNumber+1}}"
                  [class.is-selected]="legInfo?.multiRace && (legNumber === focusedLegNumber || thisLeg.getAttribute('data-leg-number') === focusedLegNumber)"
                  [attr.data-leg-number]="legNumber"
                  cduxActivateLeg
                  (activateLeg)="selectionEnabled && onActivateProgramLeg($event)">{{ legInfo?.legsCounter[legNumber].replace('RACE ', 'R') }}
              </li>
            </div>
        </ul>
    </div>

    <div class="swap-leg-container" *ngIf="legInfo?.legsCounter.length > 1 && !legInfo?.multiRace && isWagerableRace">
        <div class="swap-leg-bar" [ngClass]="checkioClass" [ngClass]="sectionClass" [class.is-compact]="isCompact" [class.has-silk]="!isBasicClass && isJockeySilksEnabled && areSilksAvailable">
            <div class="entry_col_num">
              <div class="swap-leg">
                <div class="swap-leg-title">{{ 'swap' }}</div>
              </div>
            </div>
            <div class="entry_col_checkio">
              <div *ngFor="let leg of legInfo?.legsCounter; let legNumber = index; let isFirst = first; let isLast = last" [cduxLogClick]="eventClickType.PROGRAM_LEG_SWAP" class="swap-leg" [ngClass]="{'icon-adjust': !isFirst && !isLast}">
                  <div *ngIf="!isFirst" (click)="swapLegSelections(legNumber, legNumber - 1)" [id]="'leg-' + (legNumber + 1) + '-prev'" class="swap-leg-icon previous"></div>
                  <div *ngIf="!isLast" (click)="swapLegSelections(legNumber, legNumber + 1)" [id]="'leg-' + (legNumber + 1) + '-next'" class="swap-leg-icon next"></div>
              </div>
            </div>
        </div>
    </div>

    <div class="program-header-container" [class.is-multileg]="legInfo?.legsCounter.length > 1 && isWagerableRace"
        [class.has-swap-bar]="displayMode !== DisplayModeEnum.MOBILE && legInfo?.legsCounter.length > 1 && !legInfo?.multiRace && isWagerableRace">
        <div class="entry-header" [ngClass]="sectionClass" [class.is-compact]="isCompact">

            <div class="entry_col_num"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SORTABLE_PROGRAM_NUMBER"
                [class.has-silk]="!isBasicClass && isJockeySilksEnabled && areSilksAvailable"
                id="basic-sort-by-program-number"
                (click)="sortBy(enumProgramSort.SORTABLE_PROGRAM_NUMBER)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.PROGRAM_NUMBER">
                #
            </div>
            <div class="entry_col_checkio">
                <ng-container *ngIf="isWagerableRace">
                    <ng-container *ngFor="let leg of  legInfo?.legsCounter; let legNumber = index; trackBy:trackByLegCounter">
                        <ng-container *ngIf="(legNumber === 0 && isKey); else showCheckio">
                            <div class="bet-check-bubble">
                                <i class="select-bubble-spacer"></i>
                            </div>
                        </ng-container>
                        <ng-template #showCheckio>
                            <cdux-checkiobox
                                id="program-entry-select-all-leg-{{legNumber + 1}}"
                                [isSelected]="areAllSelected[legNumber]"
                                (update)="selectAll($event, legNumber)">All
                            </cdux-checkiobox>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
            <div class="entry_col_odds"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.ODDS_RANK"
                id="basic-sort-by-odds"
                (click)="sortBy(enumProgramSort.ODDS_RANK)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.ODDS_RANK">
                <span class="label">ODDS</span>
                <span class="label-tablet-port">O</span>
            </div>

            <div class="entry_col_ml"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.MORNINGLINE_ODDS"
                id="basic-sort-by-ml"
                (click)="sortBy(enumProgramSort.MORNINGLINE_ODDS)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.MORNINGLINE_ODDS">
                <span [class.label-tablet-port]="isMobileDevice" [class.is-sortable]="!isMobileDevice"
                    [class.is-sorted-by]="!isMobileDevice && sortState?.sortProperty === enumProgramSort.MORNINGLINE_ODDS">
                    ML
                </span>
            </div>

            <div class="entry_col_pl"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.PROFITLINE_ODDS"
                id="basic-sort-by-pl"
                *ngIf="isProfitlineOddsEnabled"
                (click)="sortBy(enumProgramSort.PROFITLINE_ODDS)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.PROFITLINE_ODDS">
                <span [class.label-tablet-port]="isMobileDevice" [class.is-sortable]="!isMobileDevice"
                    [class.is-sorted-by]="!isMobileDevice && sortState?.sortProperty === enumProgramSort.PROFITLINE_ODDS">
                    PL
                </span>
            </div>

            <div class="entry_col_runner">
                Runner
            </div>

            <div class="entry_col_med-weight">
                <span class="label">{{ isGreyTrack ? 'Weight' : 'MED/WT/EQP' }}</span>
                <span class="label-tablet-port">{{ isGreyTrack ? 'W' : 'M/W/E' }}</span>
            </div>

            <div class="entry_col_jockey">
                {{riderTypeHeading}}
            </div>

            <div class="entry_col_trainer">
                Trainer
            </div>

            <div class="entry_col_sire-dam">
                Sire / Dam
            </div>

            <div class="entry_col_run-style"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.PRIOR_RUN_STYLE"
                id="basic-sort-by-run-style"
                (click)="sortBy(enumProgramSort.PRIOR_RUN_STYLE)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.PRIOR_RUN_STYLE">
                <ng-container *ngIf="!isHarnessTrack">
                    run<br *ngIf="isCompact"> style
                </ng-container>
            </div>

            <div class="entry_col_days-off"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.DAYS_OFF"
                id="basic-sort-by-run-style"
                (click)="sortBy(enumProgramSort.DAYS_OFF)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.DAYS_OFF">
                <ng-container *ngIf="isTbredTrack">
                    days<br *ngIf="isCompact"> off
                </ng-container>
            </div>

            <div *ngIf="isHarnessTrack" class="entry_col_pwr-rating"
                 [class.is-sortable]="true"
                 [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.POWER_RATING"
                 id="basic-sort-by-prime-p"
                 (click)="sortBy(enumProgramSort.POWER_RATING)"
                 [cduxLogClick]="eventClickType.PROGRAM_SORT"
                 [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                 [cduxLogClickAttributeData]="enumProgramSort.POWER_RATING">
                power<br *ngIf="isCompact"> rating
            </div>

            <div class="entry_col_avg-speed"
                [class.is-sortable]="true"
                [class.is-sorted-by]="isHarnessTrack ? sortState?.sortProperty === enumProgramSort.AVG_DISTANCE  : sortState?.sortProperty === enumProgramSort.AVG_SPEED"
                id="basic-sort-by-avg-speed"
                (click)="sortBy(isHarnessTrack ? enumProgramSort.AVG_DISTANCE : enumProgramSort.AVG_SPEED)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.AVG_SPEED">
                <ng-container *ngIf="!isCompact; else abbreviatedAvgSpeed">Average Speed</ng-container>
                <ng-template #abbreviatedAvgSpeed>Avg<br> Speed</ng-template>
            </div>

            <div *ngIf="!isHarnessTrack" class="entry_col_avg-distance"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.AVG_DISTANCE"
                id="basic-sort-by-avg-distance"
                (click)="sortBy(enumProgramSort.AVG_DISTANCE)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.AVG_DISTANCE">
                <ng-container *ngIf="!isCompact; else abbreviatedAvgDistance">Average Distance</ng-container>
                <ng-template #abbreviatedAvgDistance>Avg<br> @ Dist</ng-template>
            </div>

            <div class="entry_col_best-speed"
                [class.is-sortable]="true"
                [class.is-sorted-by]="isHarnessTrack ? sortState?.sortProperty === enumProgramSort.BEST_SPEED_HARN : sortState?.sortProperty === enumProgramSort.BEST_SPEED"
                id="basic-sort-by-best-speed"
                (click)="sortBy(isHarnessTrack ? enumProgramSort.BEST_SPEED_HARN : enumProgramSort.BEST_SPEED)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.BEST_SPEED">
                best<br *ngIf="isCompact"> speed
            </div>

            <div *ngIf="isTbredTrack" class="entry_col_prime-p"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.PRIME_POWER"
                id="basic-sort-by-prime-p"
                (click)="sortBy(enumProgramSort.PRIME_POWER)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.PRIME_POWER">
                prime<br *ngIf="isCompact"> power
            </div>

            <div class="entry_col_last-class"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.LAST_CLASS"
                id="basic-sort-by-prime-p"
                (click)="sortBy(enumProgramSort.LAST_CLASS)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.LAST_CLASS">
                last<br *ngIf="isCompact"> class
            </div>

            <div class="entry_col_avg-class"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.AVG_CLASS"
                id="basic-sort-by-prime-p"
                (click)="sortBy(enumProgramSort.AVG_CLASS)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.AVG_CLASS">
                <ng-container *ngIf="!isCompact; else abbreviatedAvgClass">Average Class</ng-container>
                <ng-template #abbreviatedAvgClass>Avg<br> Class</ng-template>
            </div>

            <div *ngIf="isTbredTrack" class="entry_col_early-pace-1"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.EARLY_PACE_1"
                id="basic-sort-by-ep1"
                (click)="sortBy(enumProgramSort.EARLY_PACE_1)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.EARLY_PACE_1">
                early<br *ngIf="isCompact"> pace 1
            </div>

            <div *ngIf="isHarnessTrack" class="entry_col_early-pace-1"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.EARLY_PACE"
                id="basic-sort-by-ep1"
                (click)="sortBy(enumProgramSort.EARLY_PACE)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.EARLY_PACE">
                early<br *ngIf="isCompact"> pace
            </div>


            <div *ngIf="isTbredTrack" class="entry_col_early-pace-2"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.EARLY_PACE_2"
                id="basic-sort-by-ep2"
                (click)="sortBy(enumProgramSort.EARLY_PACE_2)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.EARLY_PACE_2">
                early<br *ngIf="isCompact"> pace 2
            </div>

            <div *ngIf="isHarnessTrack" class="entry_col_early-pace-2"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.MID_PACE"
                id="basic-sort-by-ep2"
                (click)="sortBy(enumProgramSort.MID_PACE)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.MID_PACE">
                mid<br *ngIf="isCompact"> pace
            </div>

            <div *ngIf="isTbredTrack" class="entry_col_late-pace"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.LATE_PACE"
                id="basic-sort-by-lp"
                (click)="sortBy(enumProgramSort.LATE_PACE)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.LATE_PACE">
                late<br *ngIf="isCompact"> pace
            </div>

            <div *ngIf="isHarnessTrack" class="entry_col_late-pace"
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.LATE_PACE_H"
                id="basic-sort-by-lp"
                (click)="sortBy(enumProgramSort.LATE_PACE_H)"
                [cduxLogClick]="eventClickType.PROGRAM_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.PROGRAM_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumProgramSort.LATE_PACE_H">
                finish
            </div>

            <!-- Program Summary Columns -->

            <div class="summary_col_days-off" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_DAYS_OFF"
                (click)="sortBy(enumProgramSort.SUMMARY_DAYS_OFF)">
                DAYS<br> OFF
            </div>
                
            <div class="summary_col_run-style" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_RUN_STYLE"
                (click)="sortBy(enumProgramSort.SUMMARY_RUN_STYLE)">
                RUN<br> STYLE
            </div>
            
            <div class="summary_col_avg-speed" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_AVG_SPD"
                (click)="sortBy(enumProgramSort.SUMMARY_AVG_SPD)">
                AVG<br> SPD
            </div>
                
            <div class="summary_col_back-speed" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_BACK_SPD"
                (click)="sortBy(enumProgramSort.SUMMARY_BACK_SPD)">
                BACK<br> SPD
            </div>
                
            <div class="summary_col_late-speed" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_SPD_LR"
                (click)="sortBy(enumProgramSort.SUMMARY_SPD_LR)">
                SPD<br> LR
            </div>
                
            <div class="summary_col_avg-class" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_AVG_CLS"
                (click)="sortBy(enumProgramSort.SUMMARY_AVG_CLS)">
                AVG<br> CLS
            </div>
            
            <div class="summary_col_prime-p" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_PRM_PWR"
                (click)="sortBy(enumProgramSort.SUMMARY_PRM_PWR)">
                PRM<br> PWR
            </div>
                
            <div class="summary_col_jockey-wins" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_JOCKEY_WN"
                (click)="sortBy(enumProgramSort.SUMMARY_JOCKEY_WN)">
                W%<br> JKY
           </div>
                
            <div class="summary_col_trainer-wins" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_TRAINER_WN"
                (click)="sortBy(enumProgramSort.SUMMARY_TRAINER_WN)">
                W%<br> TRN
            </div>

            <div class="summary_col_earnings" 
                [class.is-sortable]="true"
                [class.is-sorted-by]="sortState?.sortProperty === enumProgramSort.SUMMARY_MONEY_WON"
                (click)="sortBy(enumProgramSort.SUMMARY_MONEY_WON)">
                $<br> WON
            </div>

            <!-- end Program Summary section -->

            <div class="entry_col_top-pick">
                top pick
            </div>

            <div class="entry_col_best-bet">
                best bet
            </div>

            <div class="entry_col_hot-jockey">
                hot jockey
            </div>

            <div class="entry_col_hot-trainer">
                hot trainer
            </div>

            <div class="entry_col_combo">
                combo
            </div>

            <div class="entry_col_key-trainer">
                key trainer
            </div>

            <div class="entry_col_best-distance">
                best distance
            </div>

            <div class="entry_col_horse-for-course">
                horse for course
            </div>

            <div class="entry_col_clocker-special">
                clocker special
            </div>

            <div class="entry_col_exiting-key-race">
                exiting key race
            </div>

            <!--angles shows when all other pace, class, and speed special columns are collapsed for compact view -->
            <div class="entry_col_angles">
                angles
            </div>

        </div>
    </div>
    <div class="entry-container" [class.is-compact]="isCompact">
        <div *ngFor="let entry of visibleEntries; trackBy: trackByProgramNumber">
            <cdux-program-entry [isCompact]="isCompact"
                                [entry] = "entry"
                                [rankings]="rankingsMap[entry.PostPosition]"
                                [summaryRunnerStats] = "summaryRunnerStatsMap[entry.PostPosition]"
                                (summaryRunnerSort) = "onSummaryRunnerSort($event)"
                                [summaryRunnerTopRanks] = "summaryRunnerTopRanks"
                                [trainerJockeySummary] = "trainerJockeySummaryMap[entry.PostPosition]"
                                [trainerDriverSummary] = "trainerDriverSummaryMap[entry.ProgramNumber]"
                                [angles]="anglesMap[entry.ProgramNumber]"
                                [track]="track"
                                [wagerState]="wager"
                                [isTbredTrack]="isTbredTrack"
                                [isGreyTrack]="isGreyTrack"
                                [isHarnessTrack]="isHarnessTrack"
                                [isBasicClass]="isBasicClass"
                                [isNorthAmericanTrack]="isNorthAmericanTrack"
                                [displaySilk]="!isBasicClass && isJockeySilksEnabled && areSilksAvailable"
                                [sectionClass]="sectionClass"
                                [checkioClass]="checkioClass"
                                [section]="section"
                                [viewableWagerData]="viewableWagerData"
                                [view]="view"
                                [activeLeg]="focusedLegNumber"
                                [isFavoriteRunner]="checkFavoriteRunner(entry.EntryId)"
                                [isFavoriteTrainer]="checkFavoritePerson(entry.TrainerId, 'trainer')"
                                [isFavoriteJockey]="checkFavoritePerson(entry.JockeyId, 'jockey')"
                                [cduxQAAutomation]="'entry'"
                                [showExpertPick]="!isExpertPicksHidden">
                    <ng-template ngFor [ngForOf]="legInfo?.legsCounter" [ngForTrackBy]="trackByLegCounter" let-legNumber="index"  *ngIf="isWagerableRace">
                        <ng-container *ngIf="legInfo?.multiRace ? getLegEntry(entry.ProgramNumber, legNumber): entry; else emptyCheckio let legEntry">
                            <cdux-checkiobox
                                id="program-entry-select-{{legEntry.ProgramNumber}}-leg-{{legNumber + 1}}"
                                [isSelected]="!!selectedProgramNumbers[legNumber] && selectedProgramNumbers[legNumber][legEntry.ProgramNumber]"
                                [isActive]="!legEntry.Scratched"
                                [class.is-inactive]="getDisabled(legEntry, legNumber)"
                                (update)="selectionEnabled && selectRunner($event, legEntry.BettingInterest, legNumber)">
                            </cdux-checkiobox>
                        </ng-container>
                        <ng-template #emptyCheckio>
                            <div class="bet-check-bubble">
                                <i class="select-bubble-spacer"></i>
                            </div>
                        </ng-template>
                    </ng-template>
                </cdux-program-entry>
            </div>
            <div *ngIf="isSummaryClass">
                <cdux-program-summary-racestats
                    [isCompact]="isCompact"
                    cduxDisplayModes
                    [displayMode]="displayMode"
                    [summaryRaceStats]="summaryRaceStats">
                </cdux-program-summary-racestats> 
            </div>
    </div>
</div>
