import { Component } from '@angular/core';
import { EventClickType, FeatureToggleDataService, enumFeatureToggle, JwtSessionService } from '@cdux/ng-common';
import { AbstractMenuItemComponent } from './abstract-menu-item.component';
import { Router } from '@angular/router';
import { SidebarService } from '../../sidebar/sidebar.service';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { MenuItemsEnum } from '../enums/menu-items.enum';
import { DrawerService } from 'app/shared/common/services';

@Component({
    selector: 'cdux-registration-menu-item',
    templateUrl: './registration-menu-item.component.html'
})
export class RegistrationMenuItemComponent extends AbstractMenuItemComponent {

    /**
     * Used for determining when an external call is made to open the registration in a nav panel.
     * @type {MenuItemsEnum}
     */
    protected navTarget = MenuItemsEnum.REGISTRATION;

    /**
     * Used for determining if new registration is toggled on
     */
    protected fullPageToggleOn;
    /**
     * The event to be communicated.
     *
     * @type {EventClickType}
     */
    protected clickEvent = EventClickType.MAIN_NAV_JOIN_NOW;

    /**
     * Constructor
     *
     * @param {Router} _router
     * @param {SidebarService} _sidebarService
     * @param {EventTrackingService} _eventTrackingService
     * @param {DrawerService} _drawerService
     */
    constructor(private _router: Router,
                protected _sidebarService: SidebarService,
                protected _eventTrackingService: EventTrackingService,
                private _featureToggle: FeatureToggleDataService,
                private _sessionService: JwtSessionService,
                protected _drawerService: DrawerService
            ) {
        super(_sidebarService, _eventTrackingService, _drawerService);
    }

    /**
     * Activates the Sign In nav panel.
     */
    protected activate() {
        if (this._featureToggle.isFeatureToggleOn(enumFeatureToggle.TUX_FULLPAGE_REG)) {
            this._sessionService.redirectLoggedInUserUrl = this._router.serializeUrl(
                this._router.parseUrl(this._router.url)
            );
            this._router.navigate(['/join-now']);
        } else {
            let params = { action: 'registration' };
            let routerUrl = this._router.url;
            if (routerUrl.indexOf('?') >= 0) {
                routerUrl = this._router.url.split('?', 1)[0];
                params = Object.assign(params, this._router.parseUrl(this._router.url).queryParams);
            }
            this._router.navigate([routerUrl], {queryParams: params});
        }
    }

    /**
     * Dectivates the Sign In nav panel.
     */
    protected deactivate() {
        this._router.navigate([this._router.url.split('?')[0]]);
        this._sidebarService.close(true);
    }

}
