import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { Bet, JwtSessionService } from '@cdux/ng-common';
import { CduxStorageService } from '@cdux/ng-platform/web'
import { AbstractMenuItemComponent } from './abstract-menu-item.component';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { SidebarService } from '../../sidebar/sidebar.service';
import { MenuItemsEnum } from '../enums/menu-items.enum';
import { BetsContainerComponent } from '../../bets/components/bets-container/bets-container.component';
import { BetSlipBusinessService } from '../../bet-slip/services/bet-slip.business.service';
import { of } from 'rxjs';
import { tap, map, switchMap, delay } from 'rxjs/operators';
import { DrawerService } from 'app/shared/common/services';

const BETS_MENU_ITEM = 'bets_menu_item';
@Component({
    selector: 'cdux-bets-menu-item',
    templateUrl: './bets-menu-item.component.html'
})
export class BetsMenuItemComponent extends AbstractMenuItemComponent implements OnInit {

    /**
     * Waypoint key for the bets menu item.
     *
     * @type {string}
     */
    public readonly BETS_MENU_ITEM_WAYPOINT_KEY: string = BETS_MENU_ITEM;

    /**
     * Used for determining when an external call is made to open the bets in a nav panel.
     * @type {MenuItemsEnum}
     */
    protected navTarget = MenuItemsEnum.BETS;

    /**
     * TODO: Provide a click event.
     *
     * @type [EventClickType}
     */
    protected clickEvent;

    /**
     * Number of bets in the bet slip.
     *
     * @type {number}
     */
    public unplacedBets: number;

    /**
     * Flag to know if we have just added a bet so we can
     * signal in the UI.
     */
    public isShowingBetAdded: boolean = false;
    public highlightBetLabel: boolean;

    /**
     * Constructor
     *
     * @param {JwtSessionService} _sessionService
     * @param _cduxStorageService
     * @param {SidebarService} _sidebarService
     * @param {BetSlipBusinessService} _betSlipService
     * @param {EventTrackingService} _eventTrackingService
     * @param {ChangeDetectorRef} _changeDetector
     * @param {DrawerService} _drawerService
     */
    constructor(private _sessionService: JwtSessionService,
                private _cduxStorageService: CduxStorageService,
                protected _sidebarService: SidebarService,
                protected _betSlipService: BetSlipBusinessService,
                protected _eventTrackingService: EventTrackingService,
                private _changeDetector: ChangeDetectorRef,
                protected _drawerService: DrawerService) {
        super(_sidebarService, _eventTrackingService, _drawerService);
    }

    /**
     *
     * @param bets
     * @param username
     *
     * We're replacing some duplicated code blocks used in OnInit below.
     */
    private getCurrentBetSlipBets(bets, username) {
        return bets.map( e => Bet.fromBetlike(e))
            .filter( (bet: Bet) =>
                bet.userName === username
                && bet.id !== this._betSlipService.currentBet.id
                && !bet.isSubmittedWager)
    }

    /**
     *
     * @param username
     *
     * Replacing the duplicate database code blocks
     */
    private updateUnplacedBetCount(username, destroyUnplacedBets?: boolean) {
        this._cduxStorageService.fetch({ db: Bet.DB, version: Bet.VERSION })
            .then((bets: Bet[]) => {
                if (destroyUnplacedBets === true ) {
                    const filteredBets = this.getCurrentBetSlipBets(bets, username);
                    filteredBets.forEach((bet, index) => {
                        if (bet.isSubmittedWager) {
                            this._cduxStorageService.destroy(bet);
                            filteredBets.splice(index, 1);
                        }
                    });
                    this.unplacedBets = filteredBets.length;
                } else {
                    this.unplacedBets = this.getCurrentBetSlipBets(bets, username).length;
                }
                this.highlightBetLabel = this.unplacedBets > 0;
                if (!(this._changeDetector as ViewRef).destroyed) {
                    this._changeDetector.detectChanges();
                }
            })
    }

    ngOnInit() {
        super.ngOnInit();
        const username = this._sessionService.getUserInfo().username;

        // Initial fetch of the bets.
        this.updateUnplacedBetCount(username, true);


        // Listen for changes in the bet slip.
        this._subscriptions.push(

            this._cduxStorageService.observe(Bet.DB, undefined, Bet.VERSION).pipe(
                map(bets => this.getCurrentBetSlipBets(bets, username)),
                tap(_ => this.isShowingBetAdded = false),
                delay(1),
                switchMap((theBets) => {
                    return of(theBets).pipe(
                        tap((bets: []) => {
                            if (bets.length > this.unplacedBets) {
                                this.isShowingBetAdded = true;
                            }
                            this.unplacedBets = bets.length;
                            this.highlightBetLabel = this.unplacedBets > 0;
                        }),
                        delay(6000),
                        tap(_ => this.isShowingBetAdded = false)
                    );
                })
            ).subscribe(),
            this._betSlipService.onBetChange
                .subscribe(() => {
                    this.updateUnplacedBetCount(username);
                })
        );

    }

    /**
     * Activates the Bets nav panel.
     */
    protected activate() {
        this._sidebarService.loadComponent(BetsContainerComponent.getSidebarComponent(), null, {
            clearHistory: true
        });
    }

    /**
     * Deactivates the Bets nav panel.
     */
    protected deactivate() {
        this._sidebarService.close(true);
    }

}
