import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    BasicBetType,
    enumBetModifier,
    enumFeatureToggle,
    enumRaceStatus,
    ISelectedBetAmount,
    ITrackBasic,
    FeatureToggleDataService,
    MultiRaceExoticBetType,
    WagerState,
    TrackService
} from '@cdux/ng-common';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { WageringView } from 'app/shared/wager-views/interfaces/wagering-view.interface';
import { QuickPickSidebarComponent } from 'app/shared/bets/components/quick-pick-sidebar/quick-pick-sidebar.component';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { DisplayModeEnum } from 'app/shared';
import { WagerEvent } from 'app/shared/wager-views/interfaces';

@Component({
    selector: 'cdux-classic-view',
    templateUrl: './classic-view.component.html',
    styleUrls: ['classic-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassicViewComponent implements WageringView {

    public WageringViewEnum = WageringViewEnum;
    public DisplayModeEnum = DisplayModeEnum
    public ViewSectionEnum = ViewSectionEnum;
    public isWagerableRace: boolean = false;

    @Input() public set wager(value: WagerState) {
        if (!TrackService.isSameTrack(value?.basicTrack, this._wagerState?.basicTrack) ||
            value?.basicTrack?.RaceNum !== this._wagerState?.basicTrack?.RaceNum) {
            this.quickPickSidebar?.close();
        }
        this._wagerState = value;
    }
    public get wager(): WagerState {
        return this._wagerState;
    }
    private _wagerState: WagerState;

    @Input() public view: WageringViewEnum;

    @Output() public viewChange = new EventEmitter<WageringViewEnum>();
    @Output() public sectionChange = new EventEmitter<ViewSectionEnum>();
    @Output() public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();
    @Output() public updateBetType: EventEmitter<BasicBetType| MultiRaceExoticBetType> = new EventEmitter<BasicBetType| MultiRaceExoticBetType>();
    @Output() public updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();
    @Output() public updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() public updateBetNav: EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])> = new EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])>();
    @Output() public updateEntrySelection: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();
    @Output() public resetEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public stashEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public restoreEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public outboundEvents: EventEmitter<WagerEvent> = new EventEmitter<WagerEvent>();

    private _section: ViewSectionEnum;

    public get section() {
        return this._section;
    }

    @Input() public set section(section: ViewSectionEnum) {
        this._section = section;
    }

    private _raceStatus: enumRaceStatus;

    public get raceStatus() {
        return this._raceStatus;
    }

    @Input() public set raceStatus(status: enumRaceStatus) {
        this._raceStatus = status;
        this.isWagerableRace = TrackService.isWagerableRace(status);
    }

    @ViewChild(QuickPickSidebarComponent)
    public quickPickSidebar: QuickPickSidebarComponent;
    public isQuickPickSidebarEnabled = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.QUICK_PICK);

    public isPoolsSidebarEnabled = this.featureToggleService.isFeatureToggleOn('POOLS_SIDEBAR');

    constructor (private featureToggleService: FeatureToggleDataService) { }

    public selectSection(section: ViewSectionEnum) {
        this.sectionChange.emit(section);
    }

    public onUpdateBetType(betType: (BasicBetType | MultiRaceExoticBetType)) {
        this.quickPickSidebar?.close();
        this.updateBetType.emit(betType);
    }

    public onUpdateBetModifier(betModifier: enumBetModifier) {
        this.quickPickSidebar?.close();
        this.updateBetModifier.emit(betModifier);
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        this.quickPickSidebar?.close();
        this.updateBetAmount.emit(betAmount);
    }

    public onUpdateEntrySelections(selection: ISelectionUpdate[]): void {
        this.quickPickSidebar?.close();
        this.updateEntrySelection.emit(selection);
    }

    public onResetEntrySelections() {
        this.resetEntrySelections.emit();
    }

    public onStashEntrySelections() {
        this.stashEntrySelections.emit();
    }

    public onRestoreEntrySelections() {
        this.restoreEntrySelections.emit();
    }
}
