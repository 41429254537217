import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FundingService } from '../../../shared/services/funding.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingAbstractMethodWithdrawComponent } from '../abstract-method-withdraw.component';
import { FundingPaypalService } from '../../../shared/services/paypal.service';
import { CurrencyPipe } from '@angular/common';
import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { SIDEBAR_LOADERS } from 'app/shared/sidebar/enums/loader.enums';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import { FundingPaypalMethodHeaderComponent } from './paypal-method-header.component';
import { enumWithdrawOptions } from '../abstract-method.component';
import { Router } from '@angular/router';
import { HeaderService } from 'app/shared/header/services/header.service';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { FullPageFundingConstants } from '../../../full-page-funding/full-page-funding.constants';
import { enumFundingDisplayStyle } from '../../../shared/enums/funding-display-style.enum';
import { FundingEzmoneyMethodWithdrawComponent } from '../ezmoney';

@Component({
    selector: 'cdux-funding-paypal-method-withdraw',
    templateUrl: './paypal-method-withdraw.component.html'
})
export class FundingPaypalMethodWithdrawComponent extends FundingAbstractMethodWithdrawComponent implements OnInit {

    public fpwEnabled: boolean = false; // update thru feature toggle value
    public enumWithdrawDisplayStyle = enumFundingDisplayStyle;

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingPaypalMethodWithdrawComponent, options)
    }

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingPaypalMethodWithdrawComponent, options)
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingPaypalMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        localEnvironment: ENVIRONMENT,
        localFb: UntypedFormBuilder,
        localFundingService: FundingService,
        _sidebarService: SidebarService,
        localEventTrackingService: EventTrackingService,
        localRouter: Router,
        localHeaderService: HeaderService,
        localSessionService: JwtSessionService,
        localMediaService: CduxMediaToggleService,
        _featureToggleService: FeatureToggleDataService,
        private _paypalService: FundingPaypalService,
        private _currencyPipe: CurrencyPipe,
        protected _translateService: TranslateService,
    ) {
        super(
            localEnvironment,
            localFb,
            localFundingService,
            _sidebarService,
            localEventTrackingService,
            _translateService,
            localRouter,
            localHeaderService,
            localMediaService,
            localSessionService,
            _featureToggleService
        );
        this.operationMethod = enumWithdrawOptions.PAYPAL;
    }

    public ngOnInit() {
        this.fpwEnabled = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT);
        if(this._fundingService.displayOnlineBankingActionItem(this.fundingMethodDetails)) {
            this.displayActionItem = true;
        } else {
            this._initializeForm();
        }

    }

    public continueToForm() {
        this.displayActionItem = false;
        this._initializeForm();
    }

    private _initializeForm() {
        this._initForm([
            {
                name: 'amount',
                default: 0,
                validators: [
                    Validators.min(1),
                    Validators.max(this.getMaxWithdrawAmount()),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            }
        ]);
    }

    public goToOnlineBanking() {
        const inputs = { fundingMethodDetails: this.fundingMethodDetails, withdrawBalance: this.withdrawBalance };
        this._sidebarService.loadComponent(
            FundingEzmoneyMethodWithdrawComponent.getSidebarComponent(inputs),
            FundingEzmoneyMethodWithdrawComponent.getHeaderComponent()
        );
    }

    onWithdraw(isFullPage: boolean = false) {
        if (this.form.valid && this.form.enabled) {
            this.pendingWithdraw = true;
            const amount = this.form.get('amount').value;
            const ppEmail = this.fundingMethodDetails.accountInfo;
            if (!isFullPage) {
                this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
            }
            this._paypalService.withdraw(amount, ppEmail).subscribe(
                (res) => {
                    const dollarValue = this._currencyPipe.transform(amount, 'USD', 'symbol-narrow', '1.2');
                    this._handleResponse(
                        res,
                        [
                            this._translateService.translate('withdraw-success-msg-amount', this.fundingMethodDetails.fundType, false, dollarValue),
                            ppEmail,
                            this._translateService.translate('withdraw-success-msg-delay', this.fundingMethodDetails.fundType, false)
                        ],
                        amount,
                        isFullPage
                    );
                },
                (err) => this._handleResponse(err, '')
            );
        }
    }
}
