<div class="classic-container">
    <!-- Wager Nav -->
    <div class="classic-program-container">
        <cdux-wagering-section-container
            id="{{ view + '_' + section }}"
            [view]="view"
            [displayMode]="DisplayModeEnum.LARGE"
            [section]="section"
            [raceStatus]="raceStatus"
            [wagerState]="wager"
            (sectionChange)="selectSection($event)"
            (updateRaceNav)="updateRaceNav.emit($event)"
            (updateBetNav)="updateBetNav.emit($event)"
            (updateBetType)="onUpdateBetType($event)"
            (updateBetAmount)="onUpdateBetAmount($event)"
            (updateBetModifier)="onUpdateBetModifier($event)"
            (onSelectionChange)="onUpdateEntrySelections($event)"
            (onResetEntrySelections)="onResetEntrySelections()">
        </cdux-wagering-section-container>
    </div>

    <div class="classic-ticket-container">
        <cdux-live-ticket
            (outboundEvents)="outboundEvents.emit($event)"
            [raceStatus]="raceStatus"
            [wagerState]="wager"
            (resetEntrySelections)="onResetEntrySelections()">
        </cdux-live-ticket>

        <cdux-quick-pick-sidebar *ngIf="isQuickPickSidebarEnabled && isWagerableRace"
            [class.disabled]="!quickPickSidebar?.isWagerableRace || !quickPickSidebar?.isPoolAvailable"
            [class.expanded]="quickPickSidebar?.isExpanded"
            (outboundEvents)="outboundEvents.emit($event)"
            [displayMode]="DisplayModeEnum.LARGE"
            [raceStatus]="raceStatus"
            [wagerState]="wager"
            (opened)="onStashEntrySelections()"
            (closed)="onRestoreEntrySelections()">
        </cdux-quick-pick-sidebar>

        <cdux-mybets-by-track
            [raceStatus]="raceStatus"
            [wagerState]="wager">
        </cdux-mybets-by-track>

        <cdux-pools-sidebar *ngIf="isPoolsSidebarEnabled"
            cduxDisplayModes
            [displayMode]="DisplayModeEnum.COMPACT"
            [track]="wager?.basicTrack"
            [race]="wager?.basicTrack?.RaceNum">
        </cdux-pools-sidebar>
    </div>
</div>
