import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';


import { ENVIRONMENT } from '@cdux/ng-core';
import {
    FeatureToggleDataService,
    JwtSessionService,
    TranslateService,
    enumFeatureToggle,
    ILinkTokenResponse,
    FundingStatusEnum,
    IPublicTokenExchangeRequest,
    IPlaidLinkOnSuccessMetadata,
    IPLaidError,
    IPlaidLinkOnExitMetadata,
} from '@cdux/ng-common';

import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service'

import { FundingAbstractMethodWithdrawComponent } from 'app/shared/funding/components/methods/abstract-method-withdraw.component';
import { FundingEzmoneyService } from 'app/shared/funding/shared/services/ezmoney.service';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { FundingEzMoneyMethodHeaderComponent } from './ezmoney-method-header.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { SIDEBAR_LOADERS } from 'app/shared/sidebar/enums/loader.enums';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { enumWithdrawOptions } from 'app/shared/funding/components/methods/abstract-method.component';
import { HeaderService } from 'app/shared/header/services/header.service';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { buttonTextEnum } from 'app/shared/funding/shared/enums/funding-ui.enum';
import { PlaidBusinessService } from "app/shared/funding/shared/services/plaid.business.service";
import { of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { IFundingAccountDetails } from "app/shared/funding/shared/interfaces/funding.interfaces";

@Component({
    selector: 'cdux-funding-ezmoney-method-withdraw',
    templateUrl: './ezmoney-method-withdraw.component.html'
})
export class FundingEzmoneyMethodWithdrawComponent extends FundingAbstractMethodWithdrawComponent implements OnInit, OnDestroy {

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEzmoneyMethodWithdrawComponent, options)
    }

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEzmoneyMethodWithdrawComponent, options)
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingEzMoneyMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    public fpwEnabled: boolean = false;
    public enumWithdrawDisplayStyle = enumFundingDisplayStyle;
    public plaidWithdrawEnabled: boolean;
    public buttonText: buttonTextEnum;
    public plaidMaskedAcct: string;
    protected plaidSession: IPublicTokenExchangeRequest;

    constructor(_environment: ENVIRONMENT,
                _fb: UntypedFormBuilder,
                _fundingService: FundingService,
                _sidebarService: SidebarService,
                _eventTrackingService: EventTrackingService,
                _translateService: TranslateService,
                _cdr: ChangeDetectorRef,
                _mediaService: CduxMediaToggleService,
                public router: Router,
                _headerService: HeaderService,
                protected _sessionService: JwtSessionService,
                private _ezMoneyService: FundingEzmoneyService,
                private _currencyPipe: CurrencyPipe,
                private _plaidBusinessService: PlaidBusinessService,
                _ftService: FeatureToggleDataService, ) {
        super(
            _environment,
            _fb,
            _fundingService,
            _sidebarService,
            _eventTrackingService,
            _translateService,
            router,
            _headerService,
            _mediaService,
            _sessionService,
            _ftService,
            _cdr,
        );
        this.operationMethod = enumWithdrawOptions.EZ_MONEY;
    }

    public ngOnInit() {
        this.fpwEnabled = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT);
        this.plaidWithdrawEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PLAID_WD_ENABLED);
        this.buttonText = this._plaidVerificationRequired() ? buttonTextEnum.LOGIN_TO_BANK : buttonTextEnum.WITHDRAW;
        this._initForm([
            {
                name: 'amount',
                default: 0,
                validators: [
                    Validators.min(1),
                    Validators.max(this.getMaxWithdrawAmount()),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            }
        ]);
    }

    public ngOnDestroy(): void {
        this._plaidBusinessService.closePlaidWidget();
    }

    private _plaidVerificationRequired(): boolean {
        return this.plaidWithdrawEnabled && !this.fundingMethodDetails.verified;
    }

    private _openPlaid() {
        const linkTokenObs = this._plaidBusinessService.postLinkToken(true);
        this._plaidBusinessService.initializePlaid()
            .pipe(
                switchMap((scriptLoaded) => scriptLoaded ? linkTokenObs : of(null)),
                catchError((err) =>  of(null)),
                take(1),
            )
            .subscribe((res: ILinkTokenResponse) => {
                if (res?.linkToken) {
                    const config = {
                        token: res.linkToken,
                        onSuccess: this._handlePlaidSuccess(),
                        onLoad: () => {},
                        onExit: this._handlePlaidExit(),
                        onEvent: () => {},
                    }
                    this._plaidBusinessService.openPlaidWidget(config);
                } else if(res?.status === FundingStatusEnum.FAILED) {
                    this._handleResponse(
                        res,
                        "",
                        this.form.get('amount').value,
                        false,
                    );
                } else {
                    this._handleResponse({status: FundingStatusEnum.FAILED, errorCode: 'plaid-withdraw-fallback'}, "", this.form.get('amount').value, false);
                }
            })
    }

    private _handlePlaidSuccess(): (public_token: string, metadata: IPlaidLinkOnSuccessMetadata) => void {
        const self = this;
        return (public_token: string, metadata: IPlaidLinkOnSuccessMetadata) => {
            if (metadata.accounts.length > 0) {
                this.plaidMaskedAcct = metadata.accounts[0].mask;
            }
            const linkedForWithdraw = true;

            self.plaidSession = this._plaidBusinessService.createPlaidSession(public_token, metadata, linkedForWithdraw);

            this._onWithdraw();
        }
    }

    private _handlePlaidError(err: IPLaidError, metadata: IPlaidLinkOnExitMetadata) {
        const errAndMetadata = this._plaidBusinessService.createErrAndMetadata(err, metadata);
        this._plaidBusinessService.logPlaidError(errAndMetadata).pipe(take(1)).subscribe((resp   ) => {
        })
    }

    private _handlePlaidExit(): (err: IPLaidError, metadata: IPlaidLinkOnExitMetadata) => void {
        const self = this;
        return (err: IPLaidError, metadata: IPlaidLinkOnExitMetadata) => {
            if (!!err) {
                self._handlePlaidError(err, metadata);
                self._handleResponse({status: FundingStatusEnum.FAILED, errorCode: 'plaid-withdraw-fallback'}, "", this.form.get('amount').value, false);
            }
        }
    }

    /**
     * Triggers a withdraw in the Business Service
     */
    private _onWithdraw() {
        this.pendingWithdraw = true;
        const amount = this.form.get('amount').value;
        let accountDetails: IFundingAccountDetails = null;
        let accountInfo;
        if (this._plaidVerificationRequired()) {
            accountDetails = this._formatAccountDetails();
            accountInfo = this.plaidMaskedAcct;
        } else {
            accountInfo = this.fundingMethodDetails.accountInfo;
        }
        this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
        this.successMessage = `${this._currencyPipe.transform(amount, 'USD', 'symbol-narrow')} has been withdrawn through Online Banking with account ending in ${accountInfo}.`;
        this._ezMoneyService.withdraw(amount, accountDetails).subscribe((res) => {
            this._handleResponse(
                res,
                this.successMessage,
                amount
            );
        });
    }

    private _formatAccountDetails(): IFundingAccountDetails {
        if(this._plaidVerificationRequired()) {
            return {
                plaidSession: this.plaidSession
            }
        }
    }

    public onWithdraw() {
       if (this._plaidVerificationRequired()) {
           this._openPlaid();
       } else {
           this._onWithdraw();
       }
    }


}
