<!--
  This component is used to render out the various funding methods available to our users as cards/tiles.
  When adding new methods, remember to keep it as dynamic as possible to ensure minimal code reproduction.
  Usage is as follows:
  <cdux-funding-option fundingMethod="ezmoney" [isActive]="true" accountSuffix="5504"></cdux-funding-option>
  The previous example shows that the component accepts three Inputs, fundingMethod, isActive, and accountSuffix.
  fundingMethod - An enum (ezmoney/creditcard) used to determine which method is being rendered, this is the same as the class names in mint-julep.
  isActive - A boolean used to determine whether or not the method is the currently active one.
  accountSuffix - used to determine whether or not the method is configured, and additionally which meta information to display.
-->
<ng-container [ngSwitch]="displayStyle">

<ng-template [ngSwitchCase]="enumDepositDisplayStyle.SIDEBAR">
  <div class="withdraw-option-card" [id]="id" [ngClass]="withdrawOptionClassName" [class.error]="hasError" (click)="onMethodClick()">

    <div class="withdraw-option-card__header--no-fees" *ngIf="fundingMethodDetails.fundId ===
    FundingMethodTypes.EZMONEY || fundingMethodDetails.fundId === FundingMethodTypes.EZMONEY_W">No Fees</div>

    <div [innerHTML]="icon"></div>

    <div class="withdraw-option-card__text-container">
      <div class="withdraw-option-card__title">
        {{ 'title' | cduxTranslate:fundingMethodDetails.fundType }}
      </div>

      <div *ngIf="fundingMethodDetails.accountInfo">
        <div [ngSwitch]="fundingMethodDetails.fundType">
          <ng-template [ngSwitchCase]="FundingMethodTypes[FundingMethodTypes.CREDITCARD]">
            <i class="symbol--bullet--four"></i>
            <i class="symbol--bullet--{{ isCardTypeAmex ? 'six': 'four'}}"></i>
            <i class="symbol--bullet{{ isCardTypeAmex ? '': '--four'}}"></i>
            <span class="number" [id]="id + '-accountInfo'"> {{ fundingMethodDetails.accountInfo }} </span>
          </ng-template>
          <ng-template [ngSwitchCase]="FundingMethodTypes[FundingMethodTypes.PAYPAL]">
            <span class="number paypal" [id]="id + '-accountInfo'"> {{ fundingMethodDetails.accountInfo }} </span>
          </ng-template>
          <ng-template [ngSwitchCase]="FundingMethodTypes[FundingMethodTypes.MONEYGRAM]">
          </ng-template>
          <ng-template [ngSwitchCase]="FundingMethodTypes[FundingMethodTypes.GREENDOTATR]">
          </ng-template>
          <ng-template ngSwitchDefault>
            <i class="symbol--bullet--six"></i>
            <span class="number" [id]="id + '-accountInfo'"> {{ fundingMethodDetails.accountInfo }} </span>
          </ng-template>
        </div>
      </div>
      <div class="text-remove" [id]="id + '-replace'" (click)="onReplaceClick($event)" *ngIf="canReplace">Replace</div>
    </div>

    <div class="withdraw-option-card__fee-label small-text">
      <div class="bold-text"> {{ withdrawMethodFee }} </div>
      {{ withdrawMethodDeliveryTime }}
    </div>
  </div>
</ng-template>


  <ng-template [ngSwitchCase]="enumDepositDisplayStyle.FULL_PAGE">
    <li [class.is-selected]="isActive && !hasError" (click)="onMethodClick()">
      <div class="label-tag" *ngIf="fundingMethodDetails.fundId === FundingMethodTypes.EZMONEY">No Fees</div>
      <i [ngClass]="'icon-nav-' + getFundingClassName()"></i>
      <div>
        <div [ngClass]="{'full-page-options-details' : fundingMethodDetails.fundId !== FundingMethodTypes.EZMONEY}" class="bold-text text--uppercase">
          <ng-container *ngIf="fundingMethodDetails.fundId === FundingMethodTypes.EZMONEY">
            Fast & Free
            <br>
          </ng-container>
          {{ fundingMethodDetails.fundType | cduxTranslate: 'fundtypes' }}
        </div>
        <div *ngIf="fundingMethodDetails.accountInfo" class="small-text">
          <i *ngIf="showBulletIcon()" class="symbol--bullet--four small-text"></i>
          <span class="number" [id]="id + '-accountInfo'"> {{ fundingMethodDetails.accountInfo }} </span>
        </div>
        <div *ngIf="getFundingMethodFee()" class="small-text bold-text"> {{ getFundingMethodFee() }} </div>
      </div>
      <i [id]="id+'-remove'" class="icon-replace-primary" (click)="onReplaceClick($event)" *ngIf="showReplaceLink() && (!isActive || hasError)"></i>
      <i [id]="id+'-remove'" class="icon-replace-light" (click)="onReplaceClick($event)" *ngIf="showReplaceLink() && isActive && !hasError"></i>
    </li>
  </ng-template>

<ng-template [ngSwitchCase]="enumDepositDisplayStyle.EXPRESS">
    <div class="funding-option" (click)="onMethodClick()">
        <div class="funding-option-icon">
            <i [ngClass]="'icon--' + (fundingMethodDetails.cardTypeID && cardType || getFundingClassName())"></i>
        </div>
        <div class="funding-option-details">
            <div *ngIf="!hideAccountDetails && !!fundingMethodDetails.accountInfo; else defaultDetails">
                <div class="funding-account-details">
                    <h3 class="funding-account-type" [ngSwitch]="fundingMethodDetails.cardTypeID">
                        <ng-container *ngSwitchCase="CreditCardTypes.VISA">Visa</ng-container>
                        <ng-container *ngSwitchCase="CreditCardTypes.MC">MasterCard</ng-container>
                        <ng-container *ngSwitchCase="CreditCardTypes.DISC">Discover</ng-container>
                        <ng-container *ngSwitchCase="CreditCardTypes.AMEX">American Express</ng-container>
                        <ng-container *ngSwitchDefault>Bank Account</ng-container>
                    </h3>
                    <span>ending in </span>
                    <span class="number" [id]="id + '-accountInfo'">{{ fundingMethodDetails.accountInfo }}</span>
                </div>
                <a *ngIf="showReplaceLink()" class="funding-account-replace-link" (click)="onReplaceClick($event)">Replace</a>
            </div>
            <ng-template #defaultDetails [ngSwitch]="fundingMethodDetails.fundId">
                <div *ngSwitchCase="FundingMethodTypes.EZMONEY">
                    <h3>Bank Account</h3>
                    <span><strong>FREE</strong> funds transferred from your bank account.</span>
                </div>
                <div *ngSwitchCase="FundingMethodTypes.CREDITCARD">
                    <h3>Credit or Debit Card</h3>
                    <span>Funds transferred from your MasterCard / Visa / American Express.</span>
                </div>
                <div *ngSwitchCase="FundingMethodTypes.PAYNEARME">
                    <h3>PayNearMe</h3>
                    <span>Generate a barcode online to add funds at a retailer near you!</span>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>
</ng-container>
