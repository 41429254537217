import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { AccountBubbleNotificationService } from '../../../notification/services/account-bubble-notification.service';
import { AbstractMenuItemComponent } from '../abstract-menu-item.component';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';
import { FavEventType } from 'app/account/favorites/favorites-event-interface';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { BetSlipBusinessService } from '../../../bet-slip/services/bet-slip.business.service';
import { MenuItemsEnum } from '../../enums/menu-items.enum';
import { DrawerService, FullDrawerOperations, FullDrawerOriginDirection, ActiveDrawerEnum  } from 'app/shared/common/services';
import { InboxFeedComponent } from './inbox-feed/inbox-feed.component';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { BrazeUtils } from '@cdux/ts-domain-braze';
import { EventClickType } from '@cdux/ng-common';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

const INBOX_MENU_ITEM = 'inbox_menu_item';

@Component({
    selector: 'cdux-inbox-menu-item',
    templateUrl: './inbox-menu-item.component.html'
})
export class InboxMenuItemComponent extends AbstractMenuItemComponent implements OnInit, OnDestroy {
    /**
     * Waypoint key for the inbox feed menu item.
     *
     * @type {string}
     */
    public readonly INBOX_MENU_ITEM_WAYPOINT_KEY: string = INBOX_MENU_ITEM;
    /**
     * Used for determining when an external call is made to open the inbox feed in a nav panel.
     * @type {MenuItemsEnum}
     */
    protected navTarget = MenuItemsEnum.INBOX;
    /**
     * TODO: Provide a click event.
     *
     * @type [EventClickType}
     */
    protected clickEvent = EventClickType.MAIN_NAV_MENU_INBOX;

    private _subscription: Subscription;
    private _destroy: Subject<undefined> = new Subject<undefined>();

    public hasNotifications = false;
    public isPhone: boolean;

    //init drawer open state to false to prevent issues with the value not being initialized by the time the drawer is opened
    protected _isDrawerOpen: boolean = false;

    public isTournamentSelected: boolean;

    constructor(
                protected _drawerService: DrawerService,
                protected _sidebarService: SidebarService,
                protected _betSlipService: BetSlipBusinessService,
                protected _eventTrackingService: EventTrackingService,
                private _mediaQuery: CduxMediaToggleService,
                private _accountBubbleNotificationService: AccountBubbleNotificationService,
                @Inject(BrazeUtils) private _brazeUtils: BrazeUtils,
                private _tournamentSessionService: TournamentsSessionService
                ) {
        super(_sidebarService, _eventTrackingService, _drawerService);
    }

    ngOnInit() {
        this.isTournamentSelected = this._tournamentSessionService.isTournamentSelected();
        this._initializePhoneMediaQuery();
        this._handleNotifications();
        super.subscribeToDrawerState();

        // listen on Inbox ContentCard DeleteAll message:
        this._accountBubbleNotificationService.on([
            FavEventType.INBOX_CONTENT_CARD_DELETE_ALL
        ]).pipe(
            takeUntil(this._destroy)
        ).subscribe((deleteEvent) => {
            if (deleteEvent) {
                this.hasNotifications = false;
            }
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
        super.ngOnDestroy();
        if (this._subscription) {
          this._subscription.unsubscribe();
        }
    }

    public toggleFeed() {
        if (!this._isDrawerOpen) {
            this.openInboxFeed();
        } else {
            this.closeInboxFeed();
        }
    }

    protected activate(): void {
        this.toggleFeed();
    }

    protected deactivate(): void {
        this.toggleFeed();
    }

    public openInboxFeed() {
        this._handleNotifications();
        this._drawerService.activeDrawer = ActiveDrawerEnum.INBOX;
        this._drawerService.toggleFullDrawer({
            component: InboxFeedComponent,
            operation: FullDrawerOperations.OPEN,
            originDirection: FullDrawerOriginDirection.RIGHT,
            title: 'Inbox',
            closeOnBackdropClick: true
        });
        this._sidebarService.close(true);
    }

    public closeInboxFeed() {
        this._drawerService.activeDrawer = ActiveDrawerEnum.NONE;
        this._drawerService.toggleFullDrawer({operation: FullDrawerOperations.CLOSE, originDirection: FullDrawerOriginDirection.RIGHT});
    }

    private _initializePhoneMediaQuery(): void {
        this._mediaQuery.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe((isPhone: boolean) => {
            this.isPhone = isPhone;
        });
    }

    private _handleNotifications() : void {
        this._brazeUtils.getContentCards().pipe(
            takeUntil(this._destroy)
        ).subscribe(
            (cards) => {
                this.hasNotifications = cards ? cards.getUnviewedCardCount()>0 : false;
            },
            (error) => {
                this.hasNotifications = false;
            });
    }
}
