import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CduxMediaToggleService, NotificationBubbleService } from '@cdux/ng-platform/web';
import { EventClickType } from '@cdux/ng-common';

import { EAccountNotificationBadge } from 'app/shared/notification/badges/account-notification-badge.enum';
import { FavEvent, FavEventType } from 'app/account/favorites/favorites-event-interface';
import { NotificationBadgeService } from 'app/shared/notification/badges/notification-badge.service';

import { AbstractMenuItemComponent } from './abstract-menu-item.component';
import { AccountBubbleNotificationService } from '../../notification/services/account-bubble-notification.service';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { MenuItemsEnum } from '../enums/menu-items.enum';
import { MyAccountContainerComponent } from '../../my-account/my-account-container/my-account-container.component';
import { SidebarService } from '../../sidebar/sidebar.service';
import { DrawerService } from 'app/shared/common/services';

@Component({
    selector: 'cdux-profile-menu-item',
    templateUrl: './profile-menu-item.component.html',
    styleUrls: ['./profile-menu-item.component.scss'],
})
export class ProfileMenuItemComponent extends AbstractMenuItemComponent implements OnInit, OnDestroy {

    @ViewChild('rootIcon', {read: ElementRef, static: true})
    protected rootIcon: ElementRef<unknown>;

    /**
     * Used for determining when an external call is made to open the profile in a nav panel.
     * @type {MenuItemsEnum}
     */
    protected navTarget = MenuItemsEnum.PROFILE;

    /**
     * The click event to be communicated for event tracking.
     *
     * @type [EventClickType}
     */
    protected clickEvent = EventClickType.MAIN_NAV_MENU_USERNAME;

    private _destroy: Subject<undefined> = new Subject<undefined>();
    public isPhone: boolean;

    /**
     * TODO: This probably belongs in AbstractMenuItemComponent, but doing
     *       so now would mean updating five other files. (Thanks, Angular DI.)
     */
    public hasNotifications = false;

    /**
     * Constructor
     *
     * @param {AccountBubbleNotificationService} _accountBubbleNotificationService
     * @param {EventTrackingService} localEventTrackingService
     * @param {CduxMediaToggleService} _mediaQuery
     * @param {SidebarService} _sidebarService
     * @param {DrawerService} _drawerService
     */
    constructor(
        localEventTrackingService: EventTrackingService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _mediaQuery: CduxMediaToggleService,
        private _notificationBadgeService: NotificationBadgeService<EAccountNotificationBadge>,
        private _notificationSvc: NotificationBubbleService,
        protected _sidebarService: SidebarService,
        protected _drawerService: DrawerService
    ) {
        super(_sidebarService, localEventTrackingService, _drawerService);
    }

    ngOnInit() {
        super.ngOnInit();
        // detect if on SG mobile:
        this._mediaQuery.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe(isPhone => {
            this.isPhone = isPhone;
        });
        // listen on favorite toggle notification to display
        this._accountBubbleNotificationService.on([
            FavEventType.FAVORITE_RUNNER_ADD,
            FavEventType.FAVORITE_RUNNER_REMOVE,
            FavEventType.FAVORITE_RUNNING_TODAY,
            FavEventType.FAVORITE_TRACK_ADD,
            FavEventType.FAVORITE_TRACK_REMOVE,
            FavEventType.ECOMMERCE_TRANSACTION_SUCCESS,
            FavEventType.TOURNAMENT_OPT_IN_SUCCESS
        ])
        .pipe(
            takeUntil(this._destroy)
        ).subscribe((favoriteEvent) => {
            // open the sidebar from myAccount:
            if (favoriteEvent) {
                this.showFavMessage(favoriteEvent);
            }
        });

        this._notificationBadgeService.notifications.pipe(
            takeUntil(this._destroy),
            tap(queue => {
                this.hasNotifications = queue.size > 0;
            }),
        ).subscribe();
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
        super.ngOnDestroy();
    }

    /**
     * Activates the Profile nav panel.
     */
    protected activate() {
        this._sidebarService.loadComponent(MyAccountContainerComponent.getSidebarComponent(), MyAccountContainerComponent.getHeaderComponent(), {
            clearHistory: true,
            disableBackdropClick: false
        });
    }

    /**
     * Deactivates the Profile nav panel.
     */
    protected deactivate() {
        this._sidebarService.close(true);
    }

    /**
     * A handler to show the favorite added/removed notification message
     * @param favEvent
     * @protected
     */
    protected showFavMessage(favEvent: FavEvent) {
        this._accountBubbleNotificationService.dispatch(null);
        this._notificationSvc.open(this.rootIcon, favEvent.payload);
    }

}
