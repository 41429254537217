import { Directive, Output, EventEmitter, Input} from '@angular/core';
import * as braze from '@braze/web-sdk';

@Directive()
export abstract class AbstractContentCardComponent {
    @Output() cardDismissed = new EventEmitter<braze.Card>();
    @Output() cardClicked = new EventEmitter<braze.Card>();
    @Input() public index: string;

    constructor() {}

    protected onCardClick(): void {
        this.cardClicked.emit(this.card);
    }

    protected _card!: braze.Card;

    @Input()
    set card(value: braze.Card) {
        this._card = value;
    }

    get card(): braze.Card {
        return this._card;
    }
}
