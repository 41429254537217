import { ChangeDetectorRef, Component, OnInit, ViewChild, TemplateRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { take, takeUntil } from 'rxjs/operators';
import { CreditcardMethodDepositAbstractComponent } from 'app/shared/funding/components/methods/creditcard/creditcard-method-deposit-abstract.component';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { CARD_TYPES, TranslateService, FeatureToggleDataService, JwtSessionService, FUND_ID } from '@cdux/ng-common';
import {
    ModalService,
    DropupModalConfig,
    DropupPrebuiltComponent,
    ModalRef,
} from '@cdux/ng-platform/web';
import { FundingCreditcardService } from 'app/shared/funding/shared/services/creditcard.service';
import { CurrencyPipe } from '@angular/common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { ENVIRONMENT } from '@cdux/ng-core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-creditcard-method-deposit-fullscreen',
    templateUrl: './creditcard-method-deposit-fullscreen.component.html',
    styleUrls: ['./creditcard-method-deposit-fullscreen.component.scss']
})
export class FundingCreditCardMethodDepositFullscreenComponent extends CreditcardMethodDepositAbstractComponent implements OnInit, AfterViewInit {

    @ViewChild('credcardFeeModal') private _creditcardFeeModal: TemplateRef<any>;
    @ViewChild('creditcardFeePortal') public creditcardFeePortal: TemplatePortal<any>;

    @Output()
    public onMethodChange: EventEmitter<ISidebarPortalComponent> = new EventEmitter<ISidebarPortalComponent>();

    protected readonly isFullscreen = true;
    public readonly CARD_TYPES = CARD_TYPES;
    public isSmallGlass = false;
    public isDontShowCcModalAgainChecked: boolean = false;

    // share enum with template
    public enumDepositDisplayStyle = enumFundingDisplayStyle;

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingCreditCardMethodDepositFullscreenComponent, options);
    }

    private _modalRef: ModalRef<any>;

    constructor(
        protected _router: Router,
        protected _translateService: TranslateService,
        protected _creditCardService: FundingCreditcardService,
        protected _currencyPipe: CurrencyPipe,
        protected _eventService: EventTrackingService,
        protected _changeDetectorRef: ChangeDetectorRef,
        _featureToggleService: FeatureToggleDataService,
        environment: ENVIRONMENT,
        fb: UntypedFormBuilder,
        fundingService: FundingService,
        sidebarService: SidebarService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        private _mediaToggle: CduxMediaToggleService,
        private _modalService: ModalService,) {
        super(
            _router,
            _changeDetectorRef,
            _eventService,
            _translateService,
            _creditCardService,
            _currencyPipe,
            localSessionService,
            environment,
            fb,
            fundingService,
            sidebarService,
            localHeaderService,
            _featureToggleService,
        );
    }

    ngOnInit() {
        this.isSmallGlass = this._mediaToggle.query('phone');
        super.ngOnInit();

    }

    ngAfterViewInit() {
        if (this._canShowCCFeeModal()) {
            this._openModal();
        }
    }

    public onDeposit() {
        if (this.disabledState || this.pendingDeposit) {
            return;
        }
        super.onDeposit();
    }

    public navigateToOptions() {
        this._router.navigate(['funding', 'deposit', 'options']);
    }

    public getIconClass(header: boolean = false): string {
        switch (this.fundingMethodDetails.cardTypeID) {
            case CARD_TYPES.VISA:
                let returnString = 'icon--visa';
                returnString += header ? ' white' : '';
                return returnString;
                break;
            case CARD_TYPES.MC:
                return 'icon--mc';
                break;
            case CARD_TYPES.AMEX:
                if (this.amexEnabled) {
                    return 'icon--amex';
                    break;
                }
                return null;
                break;
            default:
                return null;
        }
    }

    /**
     * When we do the story later, we'll need to pass this created component to the
     * parent so it can replace this instance with the newly generated instance.
     */
    public replaceMethod(): void {
        this.replaceAccountInfo = true;

        // re-validate the amount, credit card number, expiration fields, clear out cvv field
        this.form.controls['amount'].setValidators([
            Validators.min(this.getMinDepositAmount(false)),
            Validators.max(this.getMaxDepositAmount())
        ]);
        // if it is beteShare, reset default amount to min deposit amount
        // (a consolidated max amount including min from deposit option and hard min requirement like from betShare)
        if (this.isBetShare) {
            this.form.controls['amount'].setValue(this.getMinDepositAmount(false));
        }
        this.form.controls['amount'].updateValueAndValidity();
        this.form.controls['cardNumber'].updateValueAndValidity();
        this.form.controls['expiration'].updateValueAndValidity();
        this.resetCvvField(true);
    }

    public closeModal() {
        if (this.isDontShowCcModalAgainChecked) {
            this._fundingService.disableCcFeeModal();
        }
        this._modalService.closeAll();
    }

    public goToOnlineBanking() {
        this._fundingService.onMethodChange.next(FUND_ID.EZMONEY);
        this.closeModal();
    }


    private _openModal() {
        if (!this._modalRef) {
            const modalConfig = {
                overflow: 'hidden',
                hasBackdrop: true,
            };

            if (this.isSmallGlass) {
                this._modalRef = this._openDropup();
            } else {
                this._modalRef = this._modalService.open(
                    this._creditcardFeeModal,
                    modalConfig
                );
            }
            this._modalRef.afterClosed.pipe(
                take(1)
            ).subscribe(() => {
                this._modalRef = null;
            });
        }
    }


    private _openDropup(): ModalRef<any> {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.context = {
            headerText: 'did you know?',
            component: this.creditcardFeePortal,
        };

        const modalRef = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        modalRef.componentInstance.close.pipe(
            takeUntil(modalRef.afterClosed)
        ).subscribe(() => {
            modalRef.close();
            this.closeModal();
        });

        return modalRef;
    }

    private _canShowCCFeeModal(): boolean {
        const isOnlineBankingAvailable = this._fundingService.fundingOptions.some((option) =>
            option.fundId === FUND_ID.EZMONEY && !option.locked
        );
        if (this.ccModalEnabledFt && isOnlineBankingAvailable && !this._fundingService.isFirstTimeDeposit() && !this._fundingService.ccModalDisabled) {
            return true
        }
        return false
    }
}
