<div class="video-container-wrapper" #videoContainer>
    <div class="video-close-button" *ngIf="replayPP" (click)="stopReplay()"></div>
    <div [cdkPortalOutlet]="videoPortal" (attached)="onAttachedVideoContainer($event)"></div>
    <!-- <cdux-video-container
        [track]="replayPP?.track"
        [race]="replayPP?.raceNumber"
        [raceDate]="replayPP?.date"
        [disableLiveReplayToggle]="true">
    </cdux-video-container> -->
</div>

<div class="details-scroll-x">
    <table cellspacing="0" cellpadding="0" class="details-table-9col">
        <tbody>
        <tr>
            <td *ngIf="isPhone" class="replays" [class.is-mobile]="isPhone">REPLAY</td>
            <td [class.is-mobile]="isPhone">{{ getRacesText() }}</td>
            <td [class.is-mobile]="isPhone">DIST</td>
            <td [class.is-mobile]="isPhone">SURF-COND</td>
            <td [class.is-mobile]="isPhone">{{ trackType === trackTypeEnum.TBRED ? 'PRG (PP)' : 'PP' }}</td>
            <td [class.is-mobile]="isPhone">ODDS</td>
            <td [class.is-mobile]="isPhone">FIN</td>
            <td [class.is-mobile]="isPhone">BL</td>
            <td [class.is-mobile]="isPhone">JOCKEY</td>
            <td [class.is-mobile]="isPhone">COMMENT</td>
            <td *ngIf="!isPhone" class="replays" [class.is-mobile]="isPhone">REPLAY</td>
        </tr>

        <ng-container *ngIf="displayedPastPerformances?.length === 0">
            <ng-container *ngTemplateOutlet="singleCol; context: { label: 'NO RACES', suppressToggle: true }"></ng-container>
        </ng-container>

        <ng-container *ngIf="displayedPastPerformances?.length > 0">
            <ng-template ngFor let-pp [ngForOf]="displayedPastPerformances">
                <tr [cduxQAAutomation]="'past-performances'" [ngClass]="pp.finish === -2 ? 'scratch' : ''">
                    <td *ngIf="isPhone" class="replays" [class.is-mobile]="isPhone">
                        <div *ngIf="pp.replayAvailable" class="play-btn" (click)="startReplay(pp)" [cduxLogClick]="eventClickType.RESULTS_VIDEO_PLAY"></div>
                    </td>
                    <td [class.is-mobile]="isPhone">
                        <p class="date">{{ (pp.date | date: 'MMM d, yy').replace(', ', ', \'') }}</p>
                        <p class="track">{{ pp.track.DisplayName }}</p>
                        <p class="race-type">{{ pp.raceType }}</p>
                    </td>
                    <td class="pp-distance" [class.is-mobile]="isPhone">{{ pp.distance }}</td>
                    <td class="pp-conditions" [class.is-mobile]="isPhone">{{ pp.surface }}-{{ pp.conditions }}</td>
                    <td class="pp-programNumber" [class.is-mobile]="isPhone">{{ getProgramNumberDisplay(pp) }}</td>
                    <td class="pp-odds" [class.is-mobile]="isPhone">{{ pp.oddsString }}</td>
                    <td class="pp-finish" [class.is-mobile]="isPhone">{{ pp.finish !== -2 ? pp.finish : 'SCR' }}</td>
                    <td class="pp-length" [class.is-mobile]="isPhone">{{ pp.beatenLength }}</td>
                    <td class="pp-length" [class.is-mobile]="isPhone">{{ pp.shortJockeyName }}</td>
                    <td class="pp-comment" [class.is-mobile]="isPhone">{{ pp.comment }}</td>
                    <td *ngIf="!isPhone" class="replays">
                        <cdux-pop-up *ngIf="pp.replayAvailable" [path]="getPopUpWindowPath(pp)" [popUpWidth]="432" [popUpHeight]="430">
                            <div class="play-btn" [cduxLogClick]="eventClickType.RESULTS_VIDEO_PLAY" (click)="logReplayViewedEvent(pp)"></div>
                        </cdux-pop-up>
                    </td>
                </tr>
            </ng-template>
        </ng-container>
        </tbody>
    </table>
</div>

<div class="runner-details" *ngIf="error">
    <div class="no-details">
        <h1>{{ errorMessage }} </h1>
    </div>
</div>

<ng-template #singleCol let-label="label" let-isLink="isLink">
    <tr >
        <td *ngIf="isLink" (click)="togglePP()"><a>{{ label }}</a></td>
        <td *ngIf="!isLink">{{ label }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
</ng-template>
