export const LANG_EZMONEY_NAME = 'ezmoney';

export const LANG_EZMONEY_TRANS = {
  'description': 'Transfer funds to your bank account. No Fees!',
  'title': 'Fast & Free Online Banking',
  'tile-icon': '<div class="icon--vendor-logo icon--ach-small"></div>',
  'header-icon': '<div class="icon--vendor-logo icon--ezmoney white"></div>',
  'header-account-info': '<div class="account-number"><i class="symbol--bullet--six"></i> %s </div>',
  'pending-balance-title': 'Pending Online Banking Deposit',
  'pending-balance-info': 'It will take up to 4 business days before we have confirmation of a completed deposit transaction. During this time, you will be able to play with the funds, however, you will not be able to withdraw those funds until they have cleared your bank.',
};
