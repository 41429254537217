<ng-container *ngIf="displayStyle === enumFundingDisplayStyle.SIDEBAR; else fullPageTemplate">
    <div class="withdraw-options-wrapper">
        <!-- Add withdraw option child components here -->
        <!-- This will be dynamically generated in the future -->
        <div class="ts-message error" *ngIf="errorCode !== null">
            <div>
              <ng-container *ngIf="phoneEnabled || !errorCode.includes('-chat') else chatError">
                {{ errorCode | cduxTranslate:'errorcodes':true: errorCodeArgs }}
              </ng-container>
              <ng-template #chatError>
                <cdux-chat [errorCode]="errorCode"></cdux-chat>
              </ng-template>

            </div>
            <div *ngIf="withdrawalOptOutOfferNames !== null">
                <ul class="withdraw-optout-offers">
                    <li *ngFor="let offerName of withdrawalOptOutOfferNames">
                        {{ offerName }}
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="initialized && (errorCode === null || errorCode === 'offer-optout-warning')">
            <cdux-funding-withdraw-offer [balance]="withdrawBalance"></cdux-funding-withdraw-offer>
            <ng-template ngFor let-fundingOption [ngForOf]="fundingOptions">
              <p *ngIf="fundingOption.fundId === FUND_ID.EZMONEY_W && ezMoneyDisabled"
                 class="bold-text center-text text__white small-text text--leading--1half">
                Your original Online Banking transaction is still processing and can take up to 4 business days. Once cleared, you will be able to withdraw using this method.
              </p>
                <cdux-funding-option *ngIf="canDisplayFundingOption(fundingOption)"
                    [id]="'withdrawOption-' + fundingOption.fundType" [fundingMethodDetails]="fundingOption"
                    [hasError]="fundingOption.locked"
                    [canReplace]="false" [icon]="getIcon('tile-icon', fundingOption)" [disabled]="ezMoneyDisabled &&
                    fundingOption.fundId === FUND_ID.EZMONEY_W"
                    (select)="selectFundingMethod($event)">
                </cdux-funding-option>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #fullPageTemplate>
    <ng-template ngFor let-fundingOption [ngForOf]="fundingOptions">
        <cdux-funding-option
            *ngIf="canDisplayFundingOption(fundingOption)"
            [id]="'withdrawOption-' + fundingOption.fundType"
            [fundingMethodDetails]="fundingOption"
            [displayStyle]="displayStyle"
            [hasError]="fundingOption.locked"
            [canReplace]="false"
            [icon]="getIcon('tile-icon', fundingOption)"
            (select)="selectFundingMethod($event)">
        </cdux-funding-option>
    </ng-template>
</ng-template>
