<div class="inbox-feed-container" id="inbox-feed-container" #inboxFeedContainer>
  <!-- Use async pipe to subscribe to processedCards$ -->
  <ng-container *ngIf="cards$ | async as cards">
    <ng-container *ngIf="cards.length > 0; else emptyInbox">
      <div class="delete-all-container" (click)="deleteAllContentCards()" id="inbox-delete-all-button">
        <div class="icon-trash-primary"></div>
        <div class="delete-all-label">Delete All</div>
      </div>

      <!-- Iterate over processed cards -->
      <ng-container *ngFor="let processedCard of cards; trackBy: trackCardById; let i = index;">
        <!-- Use ngTemplateOutlet to render the appropriate template for each card -->
        <ng-container
          *ngIf="processedCard.template"
          [ngTemplateOutlet]="processedCard.template"
          [ngTemplateOutletContext]="{$implicit: processedCard.card, index: i, scrollContainer: scrollContainer}">
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <a href="{{ offersLink }}"><button id="inbox-offers-button" style="margin-bottom: 10px;">OFFERS</button></a>
</div>

<ng-template #emptyInbox>
  <p class="empty-inbox-message">You have no new notifications.</p>
</ng-template>

<!-- Template for image-only cards -->
<ng-template #imageOnlyCardTemplate let-card let-i="index" let-scrollContainer="scrollContainer">
  <cdux-image-only-card
    cduxInboxVisible
    (isVisible)="onVisibilityChange($event, card)"
    [scrollContainer]="scrollContainer"
    [card]="card"
    [index]="i"
  ></cdux-image-only-card>
</ng-template>
<!-- Template for classic cards -->
<ng-template #classicCardTemplate let-card let-i="index" let-scrollContainer="scrollContainer">
  <cdux-classic-card
    cduxInboxVisible
    (isVisible)="onVisibilityChange($event, card)"
    [scrollContainer]="scrollContainer"
    [card]="card"
    [index]="i"
  ></cdux-classic-card>
</ng-template>
<!-- Template for captioned image cards -->
<ng-template #captionedImageCardTemplate let-card let-i="index" let-scrollContainer="scrollContainer">
  <cdux-captioned-image-card
    cduxInboxVisible
    (isVisible)="onVisibilityChange($event, card)"
    [scrollContainer]="scrollContainer"
    [card]="card"
    [index]="i"
  ></cdux-captioned-image-card>
</ng-template>
