import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { DrawerService, FullDrawerOperations, FullDrawerOriginDirection, iFullDrawerConfig } from "app/shared/common/services";

enum drawerOpenClasses {
    'RIGHT' = 'tux-framework__drawer--horizontal--right--open',
    'LEFT' = '',
    'TOP' = '',
    'BOTTOM' = '',
}

enum drawerDefaultClasses {
    'RIGHT' = 'tux-framework__drawer--horizontal--right',
    'LEFT' = '',
    'TOP' = '',
    'BOTTOM' = ''
}

enum drawerCloseClasses {
    'RIGHT' = 'tux-framework__drawer--horizontal--right--closed',
    'LEFT' = '',
    'TOP' = '',
    'BOTTOM' = '',
}

@Component({
    selector: 'cdux-full-screen-drawer',
    templateUrl: './full-screen-drawer.component.html',
    styleUrls: ['./full-screen-drawer.component.scss']
})
export class FullScreenDrawerComponent implements OnInit{

    public title: string;
    public activeDrawerClasses: string[] = [drawerDefaultClasses.RIGHT];
    public drawerOpen: boolean;
    public closeOnBackdropClick: boolean = true;

    @ViewChild('drawerPortal', { read: ViewContainerRef})
    private _drawerPortal: ViewContainerRef;
    private _originDirection: FullDrawerOriginDirection;

    public constructor(
        private _drawerService: DrawerService
    ) {}

    ngOnInit(): void {
        this._drawerService.toggleFullDrawerEvent.subscribe((config: iFullDrawerConfig) => {
            // toggle class based on operation
            if (config.operation === FullDrawerOperations.CLOSE) {
                // flip classes to closed
                this.closeDrawer()
            } else {
                try {
                    // flip classes to open based on the origin direction
                    this._originDirection = config.originDirection;
                    this.activeDrawerClasses = [
                        drawerDefaultClasses[config.originDirection],
                        drawerOpenClasses[config.originDirection]
                    ];
                    this.drawerOpen = true;
                    this._drawerService.notifyDrawerState(this.drawerOpen);
                    // Update title
                    this.title = config.title;
                    this.closeOnBackdropClick = config.closeOnBackdropClick !== false;
                    // create component
                    const portal: any = this._drawerPortal.createComponent(config.component);
                    if (this.title.includes('Search')) {
                        portal.instance.selectedTab = this.title.split(' ')[1];
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        });
    }

    public closeDrawer() {
        this.activeDrawerClasses = [
            drawerDefaultClasses[this._originDirection],
            drawerCloseClasses[this._originDirection]
        ];
        this.drawerOpen = false;
        this._drawerService.notifyDrawerState(this.drawerOpen);
        setTimeout(() => {
            this._drawerPortal.clear();
        }, 500);
    }

    public onBackdropClick() {
        if (this.closeOnBackdropClick) {
            this.closeDrawer();
        }
    }
}
