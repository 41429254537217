export enum MenuItemsEnum {
    BETS = 'bets',
    BET_SLIP = 'bet_slip',
    TODAYS_BETS = 'todays_bets',
    FUNDING = 'funding',
    PROFILE = 'profile',
    REGISTRATION = 'registration',
    RUNNER_SEARCH = 'runner_search',
    INBOX = 'inbox'
}
