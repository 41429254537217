<form *ngIf="form && !errorCode && !fundingMethodDetails.locked" [formGroup]="form">
    <div class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
      <p>Please <span *ngIf="phoneEnabled else chat">contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</span>.</p>
    </div>
  <ng-template #chat><cdux-chat></cdux-chat></ng-template>

    <div *ngIf="!lockout">
        <header class="funding-content_header">
            <!-- back arrow hidden on desktop -->
            <i class="icon--nav-back-white" [class.header]="isSmallGlass && isBetShare" (cduxTap)="navigateToOptions()"></i>
            <i *ngIf="isSmallGlass" class="icon--close-light" (cduxTap)="close()" id="deposit-close-btn"></i>
            <h1>Credit Card</h1>
            <div class="funding-type-icon">
                <i *ngIf="replaceAccountInfo || !fundingMethodDetails?.accountInfo || fundingMethodDetails.cardTypeID === CARD_TYPES.VISA" class="icon--visa white"></i>
                <i *ngIf="replaceAccountInfo || !fundingMethodDetails?.accountInfo || fundingMethodDetails.cardTypeID === CARD_TYPES.MC" class="icon--mc"></i>
                <i *ngIf="amexEnabled && (replaceAccountInfo || !fundingMethodDetails?.accountInfo || fundingMethodDetails.cardTypeID === CARD_TYPES.AMEX)" class="icon--amex"></i>
            </div>
        </header>

        <cdux-funding-input-amount
            id="depositAmount"
            formControlName="amount"
            *ngIf="this.errorCode !== ERROR_CODES.EXPIRED"
            [inputType]="OPERATION"
            [fundType]="fundingMethodDetails.fundType"
            [min]="getMinDepositAmount(hasVerifiedAccount)"
            [max]="getMaxDepositAmount()"
            [amount]="amount"
            [offerId]="offerId"
            [phoneEnabled]="phoneEnabled"
            [displayStyle]="enumDepositDisplayStyle.FULL_PAGE">
        </cdux-funding-input-amount>

        <div *ngIf="this.errorCode !== ERROR_CODES.EXPIRED">
            <div class="funding-content_details-block">
                <!--
              THE ELEMENTS IN THE 'DETAILS-BLOCK' ARE VARIABLE AMONGST
              DEPOSIT METHODS. EVERYTHING ELSE ABOUT THE HTML IS THE SAME
              -->
                <h2>Enter Card Details</h2>

                <ng-container *ngIf="!fundingMethodDetails.accountInfo || replaceAccountInfo; else existingCardTemplate">
                    <cdux-input
                        class="form-label-hover"
                        minlengtherror="Minimum Credit Card Length is 13-digits."
                        maxlengtherror="Maximum Credit Card Length is 19-digits"
                        requirederror="Card Number is required."
                        label="Card Number"
                        [ngClass]="cardClasses"
                        [classes]="['account-num', 'fs-exclude']"
                        [icons]="icons"
                        (reset)="resetField('cardNumber')">
                        <input
                            id="cardNumber"
                            class="account-input block"
                            autocomplete="off"
                            cduxInputField
                            formControlName="cardNumber"
                            required="true"
                            minlength="16"
                            [cduxTextMask]="ccMask"
                            type="text"/>
                    </cdux-input>
                    <div id="bad-bin-error-panel" class="ts-validation error" *ngIf="badBinError">{{ fundingErrorMessages.badBin }}</div>
                </ng-container>

                <ng-template #existingCardTemplate>
                    <div *ngIf="showCurrentCardTemplate" class="account-num form-label-hover">
                        <div class="funding-field-label">
                            Credit Card Number
                        </div>
                        <div class="funding-cc-number">
                            <i class="symbol--bullet__13"></i>
                            <span>{{ fundingMethodDetails.accountInfo }}</span>
                        </div>
                        <i class="with-replace" [ngClass]="getIconClass()"></i>
                        <i (click)="replaceMethod()" class="icon-replace__secondary"></i>
                    </div>
                </ng-template>

                <div class="account-expire-cvv">
                    <ng-container *ngIf="!fundingMethodDetails.accountInfo || replaceAccountInfo">
                        <cdux-input
                            class="form-label-hover account-expire"
                            minlengtherror="Format is mm/yy"
                            maxlengtherror="Format is mm/yy"
                            requirederror="Expiration Date is required."
                            label="Expiration Date"
                            [classes]="['account-expire', 'fs-exclude']">
                            <input
                                id="expiration"
                                #expirationInput
                                autocomplete="off"
                                cduxInputField
                                formControlName="expiration"
                                required="true"
                                placeholder="MM/YY"
                                [cduxTextMask]="ccExpMask"
                                type="text" />
                        </cdux-input>
                    </ng-container>

                    <div class="cvv-wrapper" *ngIf="showCvvField">
                        <cdux-input [classes]="['fs-exclude']" class="form-label-hover account-cvv" label="Security Code" patternerror="Invalid CVV" requirederror="CVV is required.">
                            <input id="cvv"
                                   #cvvInput
                                   autocomplete="off"
                                   cduxInputField
                                   formControlName="cvv"
                                   [cduxTextMask]="cvvMask"
                                   pattern="{{ cvvPattern }}"
                                   type="text"
                                   (focus)="toggleCvvFocus(true)"
                                   (blur)="toggleCvvFocus(false)" />
                            <span class="input-bumper" [class.focused]="isCvvFocused && (!form.controls.cvv.touched || form.controls.cvv.valid)">
                                <cdux-tooltip class="fullpage-tooltip funding-tooltip" [position]="'top'" tooltipTitle="CVV">
                                    <span tooltip-toggle (click)="cvvTooltipClick()">
                                        <span class="cvv-tooltip-header"></span> <i class="icon--info"></i>
                                    </span>
                                    <span tooltip-content>{{ cvvToolTipContent | cduxTranslate:'creditcard' }}</span>
                                </cdux-tooltip>
                            </span>
                        </cdux-input>

                    </div>
                </div>

                <ng-container *ngIf="!fundingMethodDetails.accountInfo || replaceAccountInfo">
                    <div class="bill-address-header">
                        Billing Address same as residence?
                        <ng-container *ngIf="!isSmallGlass">
                            <ng-container *ngTemplateOutlet="sameAddressRadioButtons"></ng-container>
                        </ng-container>

                    </div>

                    <div class="bill-address-wrap check-radio">

                        <ng-container *ngIf="isSmallGlass">
                            <ng-container *ngTemplateOutlet="sameAddressRadioButtons"></ng-container>
                        </ng-container>

                        <div class="bill-address-entry is-not-same-residence" *ngIf="form.get('billAddressDiff').value">
                            <cdux-input [classes]="['fs-exclude']" class="form-label-hover account-num" label="Address" requirederror="Address is required.">
                                <input id="address"
                                       cduxInputField
                                       formControlName="address"
                                       maxlength="30"
                                       type="text"/>
                            </cdux-input>
                            <div class="bill-address-citystate">
                                <cdux-input [classes]="['fs-exclude']" class="form-label-hover city" label="City" requirederror="City is required.">
                                    <input id="city"
                                           cduxInputField
                                           formControlName="city"
                                           maxlength="25"
                                           type="text"/>
                                </cdux-input>
                                <cdux-input [classes]="['fs-exclude']" class="form-label-hover state" label="State" patternerror="Invalid state" requirederror="State is required.">
                                    <input id="state"
                                           cduxInputField
                                           formControlName="state"
                                           minlength="2"
                                           maxlength="2"
                                           pattern="^[a-zA-Z]{2}$"
                                           type="text"/>
                                </cdux-input>
                            </div>
                            <cdux-input [classes]="['fs-exclude']" class="form-label-hover zipcode" label="Zip Code" requirederror="Zip Code is required.">
                                <input id="zipCode"
                                       cduxInputField
                                       formControlName="zipCode"
                                       cduxNumeric
                                       minlength="5"
                                       maxlength="5"
                                       type="text"/>
                            </cdux-input>
                        </div>

                    </div>
                </ng-container>
                <cdux-funding-transaction-summary
                    transactionName="Deposit"
                    [amount]="form.get('amount').value"
                    feeName="Credit Card Fee"
                    [feeValue]="fundingMethodDetails.flatFee">
                </cdux-funding-transaction-summary>

                <div class="funding-content_details-block__footer">
                    <div class="deposit--btn large"
                        (click)="!pendingDeposit && !badBinError && onDeposit()"
                        [class.disabled]="disabledState || pendingDeposit || badBinError">
                        <span *ngIf="inboundBet && !pendingDeposit">Deposit & Bet</span>
                        <span *ngIf="!inboundBet && !pendingDeposit">Deposit</span>
                        <div *ngIf="pendingDeposit" class="gbl-button-loader">
                            <div class="gbl-loader-dots">
                                <i class="dots-animated"></i>
                                <i class="dots-animated"></i>
                                <i class="dots-animated"></i>
                                <i class="dots-animated"></i>
                            </div>
                        </div>
                    </div>
                    <div class="withdraw--tandc" *ngIf="!!depositDisclaimer">{{depositDisclaimer}}</div>
                    <div class="cash-advance-disclaimer">*Please note that credit card transactions may be processed as cash advance by your bank – subject to additional fees or interest.</div>
                </div>

            </div>
        </div>
    </div>
    <ng-template #sameAddressRadioButtons>
        <cdux-radio-group formControlName="billAddressDiff" [class.large-glass-radio-padding]="!isSmallGlass">
            <cdux-radio-option id="sameBillingRadio" [value]="false">
                Yes
            </cdux-radio-option>
            <cdux-radio-option id="diffBillingRadio" [value]="true">
                No
            </cdux-radio-option>
        </cdux-radio-group>
    </ng-template>
</form>

<ng-template #credcardFeeModal>
  <div class="modal-inner-wrap custom-modal">
    <div class="modal-header">
      <h2 class="h2--standard text--uppercase">did you know?</h2>
      <!--Close button-->
      <div class="close-btn-wrap" (click)="closeModal()">
        <i class="icon--close dark" title="Click to close box or cancel"></i>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="modalContent"></ng-container>
  </div>
</ng-template>

<ng-template cdkPortal #creditcardFeePortal="cdkPortal">
  <div class="modal-inner-wrap custom-modal credit-card-portal-margin-top">
    <ng-container *ngTemplateOutlet="modalContent"></ng-container>
  </div>
</ng-template>

<ng-template #modalContent>
  <p class="center-text small-text">There are fees associated with this deposit method, as well as any additional fees
    your institution may charge. Try Online Banking as a fast and free method.</p>
  <br>
  <div class="button-holder">
    <button class="button-outline--secondary dark-text ninety-width" (click)="goToOnlineBanking()">Try online
      banking</button>
  </div>
  <div class="button-holder">
    <button class="ninety-width" (click)="closeModal()">no thanks</button>
  </div>
  <div class="dont-show-again-checkbox credit-card-modal-checkbox">
    <input class="checkbox--standard" id="dontshow" name="dontshow" type="checkbox" [(ngModel)]="isDontShowCcModalAgainChecked">
    <label for="dontshow">Don't show again</label>
  </div>
</ng-template>
