import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AbstractMenuItemComponent } from './abstract-menu-item.component';
import { MyBetsBusinessService } from 'app/shared/bets/services/my-bets.business.service';
import { SidebarService } from '../../sidebar/sidebar.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { MenuItemsEnum } from '../enums/menu-items.enum';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';
import { DrawerService } from 'app/shared/common/services';

@Component({
    selector: 'cdux-todays-bets-menu-item',
    templateUrl: './todays-bets-menu-item.component.html',
})
export class TodaysBetsMenuItemComponent extends AbstractMenuItemComponent implements OnInit, OnDestroy {
    /**
 * TODO: Provide a click event.
 *
 * @type [EventClickType}
 */
    protected clickEvent;

    /**
    * Used for determining when an external call is made to open the bets in a nav panel.
    * @type {MenuItemsEnum}
    */
    protected navTarget: MenuItemsEnum = MenuItemsEnum.TODAYS_BETS;

    public counter: number;
    private destroy$ = new Subject<void>();

    constructor(
            protected _eventTrackingService: EventTrackingService,
            protected _sidebarService: SidebarService,
            private _myBetsBusinessService: MyBetsBusinessService,
            protected _drawerService: DrawerService
        ) {
        super(_sidebarService, _eventTrackingService, _drawerService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initializeBetCount();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initializeBetCount() {
        this._subscriptions.push(
            this._myBetsBusinessService.getMyBetsCache(this._myBetsBusinessService.tournamentsSessionService.isTournamentSelected()).pipe(
                takeUntil(this.destroy$),
                distinctUntilChanged()
            ).subscribe((wagers) => {
                this.counter =  wagers?.length;
            })
        )
    }

    protected activate(): void {
        this._sidebarService.loadComponent(TodaysBetsContainerComponent.getSidebarComponent(), null, {
            clearHistory: true,
        });
    }

    protected deactivate(): void {
        this._sidebarService.close(true);
    }

    public formatBetCount(count: number | null): string {
        if (count === null || count === undefined) {
            return '';
        }
        if (count > 999) {
            return '999+';
        }
        return count.toString();
    }
}
