import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { Subject } from 'rxjs';
import { delay, finalize, take, takeUntil } from 'rxjs/operators';

import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { ENVIRONMENT } from '@cdux/ng-core';
import {
    enumStatus,
    FeatureToggleDataService,
    FUND_ID,
    DEPOSIT_FUND_ID,
    FUNDING_OPERATIONS,
    WITHDRAWAL_FUND_ID,
    IFundingOption,
    IPaypalCompleteDepositStatus,
    IPaypalDepositStatus,
    JwtSessionService,
    TranslateService,
    CARD_DEPOSIT_CODES,
    AccountInfoDataService,
    IEZBankTransactionStatus,
    enumEZBankTransactionStatus,
    enumFeatureToggle,
} from '@cdux/ng-common';
import { LoadingDotsComponent, LoadingService } from '@cdux/ng-fragments';

import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { HeaderService } from 'app/shared/header/services/header.service';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { enumSidebarPortalComponentProperties } from 'app/shared/funding/components/methods/abstract-method.component';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { enumFundingEvent, IFundingEvent } from 'app/shared/funding/shared/interfaces/funding.interfaces';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { FundingPaypalService } from 'app/shared/funding/shared/services/paypal.service';
import { FullPageFundingConstants } from './full-page-funding.constants';
import { FundingDepositOptionsComponent } from '../components/deposit-options';
import { EzbankService } from 'app/shared/funding/shared/services/ezbank.service';
import { EZBankPageviewStatus } from 'app/shared/funding//shared/enums/ezbank-pageview-status.enum';


@Component({
    selector: 'cdux-full-page-funding',
    templateUrl: 'full-page-funding.component.html',
    styleUrls: ['full-page-funding.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FullPageFundingComponent implements OnInit, OnDestroy {


    public static readonly _OVERLAY_NAME = 'fullpageDepositLoadingOverlay';
    public static readonly FALLBACK_ERROR_ACTION_TEXT = 'Try again';
    // todo: HIP to move paypal error codes out of complete deposit component and pyaplService for easy reference
    public readonly WRONG_PAYPAL_ACCOUNT_CODE = 2131;

    public selectedOperation;
    public operationFundIds;
    public enumDepositDisplayStyle = enumFundingDisplayStyle;
    public enumFundingOperations = FUNDING_OPERATIONS;
    public hasError: boolean = false;
    public hasToteError: boolean = false;
    public hasReturnError = false; // If the external process like PayPal
                                   // after return having error or the completeDeposit/cancelDeposit process having error
    public errorActionText: string;
    public successfulDeposit: boolean = false;
    public balance: number = 0.00;
    public errorMessage: string;
    public errorCode: string;
    public selectedFundId: FUND_ID;
    public readonly FUND_ID = FUND_ID;
    public isLoading = true;
    public loadingComponent = LoadingDotsComponent;
    public isSmallGlass: boolean = false;
    public isDisabled: boolean = false;
    public depositMethodLabel: string;
    public betId: string;
    public betShareCost: string; // The at least amount user need to deposit in order to join the share
    public betShare: boolean = false; // A flag to indicate the deposit request is originated from a betShare widget(wager, or registration) due to insufficient fund
    public successDoneBetShareBackUrl: string;
    public defaultEnabledIds: FUND_ID[] = null; // for betShare, this will be only EZMoney and CreditCard, for other situation, data is retrieved from backend
    public usersname: string; // Store players firstname from JWT userInfo
    public fpdEnabled: boolean = false; // Whether the Full Page deposit feature is turned on (which include both first time and continuing deposit using full page)
    public fpwEnabled: boolean = false; // Whether the Full Page withdrawal feature is turned on
    public isFirstTimeDeposit: boolean; // a indicator user makes first deposit.
    public balanceError: string; // Error received from balance call
    public CC_ERROR_CODES = CARD_DEPOSIT_CODES;
    public successMessage: string[] = [];

    public isOffer: boolean = false; // whether this deposit originated from offer optIn
    public offerMinDepAmount: string; // min deposit amount to satisfy offer requirement
    public offerId: number; // offer ID
    public offerCode: string; // offer Code
    public isPostDeposit: boolean = false;
    public phoneEnabled: boolean;
    public showAlternateChatMessage: boolean;

    public loadingMessage: string;
    private _welcomeMessage = 'Deposit Funds Into Your Account';

    // Create getter so template can access value.
    public get _OVERLAY_NAME () {
        return FullPageFundingComponent._OVERLAY_NAME;
    }

    private _lightBGMethods = [
        FUND_ID.PAYNEARME,
    ]

    @ViewChild('depositMethod', { read: ViewContainerRef })
    private _depositMethod: ViewContainerRef;

    @ViewChild(FundingDepositOptionsComponent) private _depositOptions: FundingDepositOptionsComponent;

    public paypalAccount: string;

    private _destroy: Subject<boolean> = new Subject();
    private _enumFundIdKeys = Object.keys(FUND_ID);
    private _chosenDepositComponent: ISidebarPortalComponent;
    private _loadedFundingMethods: IFundingOption[] = [];
    private _offersRegex = new RegExp('offers');
    private _delayTime: number = 500

    constructor(
        private _activeRoute: ActivatedRoute,
        private _betSlipService: BetSlipBusinessService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _accountInfoService: AccountInfoDataService,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _currencyPipe: CurrencyPipe,
        private _environment: ENVIRONMENT,
        private _ezBankService: EzbankService,
        private _featureToggleService: FeatureToggleDataService,
        private _fundingService: FundingService,
        private _headersService: HeaderService,
        private _loadingService: LoadingService,
        private _mediaToggle: CduxMediaToggleService,
        private _paypalService: FundingPaypalService,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _translateService: TranslateService,
    ) { }

    ngOnInit() {
        // Get FULL_PG_DEPOSIT2 feature toggle
        this.fpdEnabled = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);
        this.fpwEnabled = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT);

        this.phoneEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);

        this.isSmallGlass = this._mediaToggle.query('phone');
        this._loadingService.register(FullPageFundingComponent._OVERLAY_NAME);

        // Check for a stashed bet from some prior deposit attempt
        const returningBetId = sessionStorage.getItem('postDepositReturningBetId');
        if (returningBetId) {
            sessionStorage.removeItem('postDepositReturningBetId');
            this._betSlipService.resumeStoredBet(returningBetId);
        }

        // select method based on route /:operation/:method
        this._activeRoute.paramMap.pipe(
            takeUntil(this._destroy)
        ).subscribe((params: ParamMap) => {
            if (params.has('operation') && params.has('method')) {
                this.selectedOperation = params.get('operation').toLocaleLowerCase();
                if (!Object.values(FUNDING_OPERATIONS).includes(this.selectedOperation)) {
                    this.selectedOperation = FUNDING_OPERATIONS.DEPOSIT;
                }
                const method = params.get('method').toLocaleUpperCase();

                this.operationFundIds = (this.selectedOperation === FUNDING_OPERATIONS.WITHDRAW) ? WITHDRAWAL_FUND_ID : DEPOSIT_FUND_ID;

                if (method in this.operationFundIds) {
                    /**
                     * If FundingDepositOptionsComponent's selectedMethodId
                     * (set in the template to this.selectedFundId) is present
                     * during its ngAfterViewInit, selectFundingMethod() will
                     * be called, which ultimately clears any errors.
                     *
                     * Thus, coming into a deep-link like /deposit/paypal
                     * with an error will only show that error briefly before
                     * clearing it.
                     */
                    this.selectedFundId = this.operationFundIds[method];
                } else {
                    this.selectedFundId = null;
                }
            }
        });

        // get user's firstname:
        if (this._sessionService?.getUserInfo()) {
            this.usersname = this._sessionService.getUserInfo().firstName;
        }
        // Get initial balance:
        this._fundingService.updateAccountBalance().pipe(
            take(1)
        ).subscribe(
            (balance) => {
                this.balance = balance.Balance;
            },
            (err) => {
                try {
                    this.balanceError = err.data.Error.Description;
                    // reformatting specific errors:
                    switch (this.balanceError) {
                        case 'tote is down.':
                            this.balanceError = this._translateService.translate('balance-unavailable-during-tote-down', this._environment.affiliateId.toString(), false);
                            break;
                        default:
                            this.balanceError = this._titleCaseWord(FullPageFundingConstants.BALANCE_RETRIEVAL_ERROR);
                            break;
                    }
                } catch (error) {
                    this.balanceError = this._titleCaseWord(FullPageFundingConstants.BALANCE_RETRIEVAL_ERROR);
                }
            }
        );

        this._fundingService.balanceUpdated.pipe(
            takeUntil(this._destroy)
        ).subscribe(
            (balance) => {
                this.balance = balance;
            }
        );

        // this doesn't happen for PayPal (which makes sense given routing)
        this._fundingService.onFundingEvent.pipe(
            takeUntil(this._destroy)
        ).subscribe(
            (event: IFundingEvent) => {
                switch (event.event) {
                    case enumFundingEvent.SUCCESS:
                        this._resultSuccess(event);
                        break;
                    case enumFundingEvent.ERROR:
                        this._resultError(event);
                        break;
                }
            }
        );

        // for PayPal, check the action param
        // e.g., http://localhost:4200/deposit/paypal?action=paypalCompleteDepositFP&ppTransaction=success&token=EC-7E911607KU482932W&PayerID=Q5HHJJEP5MPZ2
        //       http://localhost:4200/deposit/paypal?action=paypalCompleteDepositFP&ppTransaction=cancelled&token=EC-55B42587T37612231
        // temporarily:
        // http://localhost:4200/todays-races/time?action=paypalCompleteDeposit&ppTransaction=success&token=EC-7E911607KU482932W&PayerID=Q5HHJJEP5MPZ2
        // http://localhost:4200/todays-races/time?action=paypalCompleteDeposit&ppTransaction=cancelled&token=EC-7E911607KU482932W&PayerID=Q5HHJJEP5MPZ2
        const url = new URL(location.href);
        const actionParam = url.searchParams.get('action');
        if (actionParam) {
            this.isPostDeposit = true;
            if (actionParam === FundingPaypalService.DEEPLINK_ACTION + FullPageFundingConstants.SUFFIX_ACTION_FULL_PAGE) {
                const status = url.searchParams.get(FundingPaypalService.PAYPAL_TRANSACTION_PARAM);
                const token = url.searchParams.get('token');
                this._fundingService.postDepositRedirectURL = sessionStorage.getItem('postDepositRedirectURL');

                if (!this._paypalService.hasTransactionCompleted(token)) {
                    switch (status) {
                        case enumStatus.SUCCESS:
                            this._completePaypalDeposit(token);
                            break;
                        case enumStatus.CANCELLED:
                            this._cancelPaypalDeposit(token);
                            break;
                    }
                }
            } else if (actionParam === FullPageFundingConstants.GREENDOT_FP_DEEPLINK_ACTION) {
                // For Greendot Full page, the external third party page will redirect back
                // with URL like http(s)://{domain}/deposit/greendot?action=greendotBarcodeReceivedFP
                // we will catch those URL and show a success message
                this.successfulDeposit = true; // This will fix the deposit option tiles partially displayed before showing the success message
                this._loadingService.resolve(FullPageFundingComponent._OVERLAY_NAME, 0).then(() => {
                    this._fundingService.postDepositRedirectURL = sessionStorage.getItem('postDepositRedirectURL');
                    this.isLoading = false;
                    this._resultSuccess({event: 'SUCCESS', messages: ['Your Green Dot barcode was successfully generated!']});
                });
            } else if (actionParam === FullPageFundingConstants.MAZOOMA_DEEPLINK_ACTION) {
                if (url.searchParams.has('orderId') && !!this._ezBankService.getStoredDepositAmount(url.searchParams.get('orderId'))) {
                    this._completeMazoomaTransaction(url.searchParams.get('orderId'));
                } else {
                    // if not matching order id in storage then probably a stale link so just take to deposit page
                    this.isPostDeposit = false;
                    this.loadingMessage = this._welcomeMessage;
                }
            }
        } else {
            this.loadingMessage = this._welcomeMessage;
        }

        // For betShare deposit handling: when betShare trigger deposit, it will pass in betId, also happens for saved wager submit with insufficient funds
        this._activeRoute.queryParamMap.pipe(
            takeUntil(this._destroy)
        ).subscribe((searchParams: ParamMap) => {
            if (searchParams.has('betId')) {
                this.betId = searchParams.get('betId');
            }
            if (searchParams.has('betShareCost')) {
                this.betShareCost = searchParams.get('betShareCost');
            }
            if (searchParams.has('betShare')) {
                this.betShare = searchParams.get('betShare') === 'true';
                if (this.betShare) {
                    this.defaultEnabledIds = [FUND_ID.EZMONEY, FUND_ID.CREDITCARD];
                    if (localStorage.getItem(FullPageFundingConstants.FPD_DONE_BSHARE_BCK)) {
                        this.successDoneBetShareBackUrl = '/betshare/' + localStorage.getItem(FullPageFundingConstants.FPD_DONE_BSHARE_BCK);
                    }
                }
            }
        });

        this._fundingService.accountInfoUpdated.pipe(
            takeUntil(this._destroy)
        ).subscribe(() => {
            this.isFirstTimeDeposit = this._fundingService.isFirstTimeDeposit().valueOf();
        });

        // For Offer related deposit handling:
        this._activeRoute.queryParamMap.pipe(
            takeUntil(this._destroy)
        ).subscribe((searchParams: ParamMap) => {
            if (searchParams.has('isOffer')) {
                this.isOffer = searchParams.get('isOffer') === 'true';
                if (searchParams.has('enabledIds')) {
                    const methods: string[] = searchParams.get('enabledIds').split(',');
                    this.defaultEnabledIds = [];
                    methods.forEach((fId) => {
                        if (fId in FUND_ID) {
                            this.defaultEnabledIds.push(+fId);
                        }
                    });
                    if (this.defaultEnabledIds.length === 1) {
                        this.selectedFundId = this.defaultEnabledIds[0];
                    }
                }
                if (searchParams.has('amount')) {
                    this.offerMinDepAmount = searchParams.get('amount');
                }
                if (searchParams.has('offerId')) {
                    this.offerId = +searchParams.get('offerId');
                }
                if (searchParams.has('offerCode')) {
                    this.offerCode = searchParams.get('offerCode');
                }
            }
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    public goToFunding(operation: FUNDING_OPERATIONS) {
        this._router.navigate(['funding', operation]);
    }

    public close () {
        let redirectUrl = '/';
        if (this._sessionService.redirectLoggedInUserUrl
            && this._sessionService.redirectLoggedInUserUrl.split('/')[1] !== this._router.url.split('/')[1]) {  // Check to ensure the previous location wasn't the deposit page
            redirectUrl = this._sessionService.redirectLoggedInUserUrl;
            // Clear this variable after using it.
            this._sessionService.redirectLoggedInUserUrl = null;
        } else if (this._fundingService.postDepositRedirectURL) {
            if (this._offersRegex.test(this._fundingService.postDepositRedirectURL) && this.offerCode) {
                redirectUrl = `/offers/` + this.offerCode + '/details';
            } else {
                redirectUrl = this._fundingService.postDepositRedirectURL;
            }
        }
        // We need to tell the Header Service that we're navigating in reverse so it pops the history
        this._headersService.reverseNavigation = true;

        this._router.navigate(
            redirectUrl.replace(/\?.*/, '').split('/'),
            {
                queryParams: redirectUrl.replace(/[^?]*\??/, '').split('&').reduce((params, kv) => {
                    const [left, right] = kv.split('=');
                    params[left] = right;
                    return params;
                }, {}),
                state: {
                    returningBetId: this.betId
                }
            }
        );
    }

    public depositLater() {
        if (this.betShare) {
            this._router.navigate(['/']);
        } else {
            this.close();
        }
    }

    public fpd_done_close() {
        if (localStorage.getItem(FullPageFundingConstants.FPD_DONE_BSHARE_BCK) && this.successDoneBetShareBackUrl) {
            localStorage.removeItem(FullPageFundingConstants.FPD_DONE_BSHARE_BCK);
            // If we have "/bet" in the path, then keep it. This is specially in the case where localhost:4200 doesn't have it while ITE/FTE would have it
            const basePath = /^(\/Feature\/.+)?\/bet\b/.test(location.pathname) ? location.pathname.split('/bet')[0] + '/bet' : '';
            // Redirect back to BetShare, with reentrance flag to be true, and the checksum is simply a hash value to enforce the Url get replaced
            location.replace(basePath + this.successDoneBetShareBackUrl + '?reentrance=true&checksum=' + Math.random().toString());
        } else {
            this.close();
        }
    }

    public selectComponent($event: ISidebarPortalComponent) {
        this.isLoading = true;
        // clear #depositMethod template
        if (this._depositMethod) {
            this._depositMethod.clear();
        }

        // clear any possible error states
        this.hasError = false;
        this.hasReturnError = false;
        this.errorMessage = null;
        this.errorCode = null;
        this.showAlternateChatMessage = false;

        const fundingMethodProperties = $event.properties.inputs.get(enumSidebarPortalComponentProperties.METHOD_DETAILS_TOKEN) as Partial<IFundingOption>;
        this._chosenDepositComponent = $event;
        this.isDisabled = fundingMethodProperties.locked;
        this.depositMethodLabel = fundingMethodProperties.fundType;
        // If this is from BetShare, set the isBetShare flag and inboundBet flag, as well as minimumAmount
        if (this.betShare) {
            this._chosenDepositComponent.properties.inputs.set(enumSidebarPortalComponentProperties.IS_BET_SHARE_TOKEN, true);
            this._chosenDepositComponent.properties.inputs.set(enumSidebarPortalComponentProperties.INBOUND_BET_TOKEN, this.betShareCost);
            this._chosenDepositComponent.properties.inputs.set(enumSidebarPortalComponentProperties.MINIMUM_AMOUNT_TOKEN, +this.betShareCost);
            this._chosenDepositComponent.properties.inputs.set(enumSidebarPortalComponentProperties.AMOUNT_TOKEN, +this.betShareCost);
        }
        // If from Offers and offerMinDepositAmount set, then set the minimumAmount; if not firsttime deposit, also update default amount
        if (this.isOffer) {
            this._chosenDepositComponent.properties.inputs.set(enumSidebarPortalComponentProperties.OFFER_ID_TOKEN, this.offerId);
            if (+this.offerMinDepAmount > 0) {
                if (!this.isFirstTimeDeposit) {
                    this._chosenDepositComponent.properties.inputs.set(enumSidebarPortalComponentProperties.AMOUNT_TOKEN, +this.offerMinDepAmount);
                }
            }
        }

        // route to /deposit/{method}
        if (this.isPostDeposit) { return; }
        this._router.navigate([ 'funding', this.selectedOperation, this._lookupMethodRouteParamById(fundingMethodProperties.fundId) ]);
        this._fundingService.activeFundingMethod = this.selectedFundId = fundingMethodProperties.fundId;
        this._loadingService.resolve(FullPageFundingComponent._OVERLAY_NAME, 0).then(() => {
            this.isLoading = false;
            this._showDepositComponent();
        });
    }

    public displayOptions($event: boolean) {
        if ($event) {
            this.isLoading = false;
        }
    }

    public clearErrors() {
        this.isPostDeposit = false;
        this.hasError = false;
        this.hasReturnError = false;
        this.errorMessage = null;
        this.errorCode = null;
        this.showAlternateChatMessage = false;
        this.errorActionText = null;
        if (this._depositMethod) {
            this._depositMethod.clear();
        } // USER-6588 fix, if not clear, it will show double Paypal deposit form stacked.
        this._showDepositComponent();
    }

    public useLightBackground() {
        return this.hasError || this._lightBGMethods.includes(this.selectedFundId) || this.hasReturnError || this.isDisabled;
    }

    // https://stackoverflow.com/a/62376649/356016
    private _lookupMethodRouteParamById (fundID: number): string {
        const matches = this._enumFundIdKeys.filter((key) => this.operationFundIds[key] === fundID);
        return matches.length > 0 ? matches[0].toLocaleLowerCase() : FullPageFundingConstants.ROUTE_PARAM_DUMMY;
    }

    private _resultSuccess (event: IFundingEvent = null) {
        this._loadingService.resolve(FullPageFundingComponent._OVERLAY_NAME, 0).then(() => {
            this.isLoading = false;
            this.isPostDeposit = false;
            this.successfulDeposit = true;
            const OPERATION = this.selectedOperation === FUNDING_OPERATIONS.DEPOSIT ? 'deposited into' : 'withdrawn from';
            this.successMessage = event?.messages?.[0] ? event.messages : [(event?.amount ? (this._currencyPipe.transform(event.amount, 'USD') + ' has been ' + OPERATION + ' your account.') : null)];
            this._changeDetectorRef.markForCheck();
            if (this.selectedOperation === this.enumFundingOperations.DEPOSIT) {
                setTimeout(() => {
                    this.onDoneClick();
                }, 5000);
            }
        });
    }

    public onDoneClick() {
        if (this.betShare) {
            this.fpd_done_close();
        } else {
            this.close();
        }
    }

    private _resultError (event: IFundingEvent) {
        const messages = !!event?.messages ? event.messages : [];
        const errorCode = event.code;
        this._chosenDepositComponent.properties.inputs.set('previousFundingEvent', event);
            this.isPostDeposit = false;
        if (this._depositMethod) {
            this._depositMethod.clear();
        }
        this.successfulDeposit = false;
        this.hasError = true;
        this.errorCode = errorCode;
        const key = errorCode + '-chat';
        this.showAlternateChatMessage = !this.phoneEnabled && key != this._translateService.translate(errorCode + '-chat', 'errorcodes', false);
        this.errorActionText = this._translateService.translationExists(this.errorCode + '-action-text', 'errorcodes')
            ? this._translateService.translate(this.errorCode + '-action-text', 'errorcodes', false) : FullPageFundingComponent.FALLBACK_ERROR_ACTION_TEXT;
        this.errorMessage = Array.isArray(messages) && messages.length > 0 ? messages[0] : FullPageFundingConstants.DEFAULT_ERROR_MESSAGE;
        this._changeDetectorRef.markForCheck();
    }

    private _showDepositComponent() {
        // clear #depositMethod template
        if (this._depositMethod) {
            this._depositMethod.clear();
        }

        const component = this._depositMethod.createComponent(this._componentFactoryResolver.resolveComponentFactory(this._chosenDepositComponent.component));
        component.instance['setProperties'](this._chosenDepositComponent.properties);

        component.instance['error'].pipe(
            takeUntil(this._destroy)
        ).subscribe(
            (error) => {
                this.hasError = error;
                this._changeDetectorRef.markForCheck();
            }
        );
    }

    private _completePaypalDeposit(token: string) {
        this.selectedFundId = FUND_ID.PAYPAL;
        // Call paypalService completeDeposit()
        this._paypalService.completeDeposit(token)
            .subscribe(
                (res: IPaypalCompleteDepositStatus) => {
                    if (res.errorCode) {
                        // Handle Soft Errors
                        this._handlePayPalException(res);
                    } else {
                        this._fundingService.updateAccountBalance().pipe(
                            take(1),
                            finalize(() => {
                                this._fundingService.onFundingEvent.emit({
                                    event: 'SUCCESS',
                                    messages: [`${this._currencyPipe.transform(res.amount, 'USD')} has been deposited to your account with PayPal.`],
                                    amount: res.amount
                                });
                            })
                        ).subscribe();
                    }
                },
                (err) => this._handlePayPalException(err)
            );
    }

    private _cancelPaypalDeposit(token: string) {
        this.selectedFundId = FUND_ID.PAYPAL;
        // Call paypalService cancelDeposit()
        this._paypalService.cancelDeposit(token)
            .pipe(delay(this._delayTime)).subscribe(
            (res: IPaypalDepositStatus) => {
                if (!res.errorCode) {
                    const errorMsg = this._translateService.translate('deposit-canceled', FUND_ID[FUND_ID.PAYPAL]); // 'Your PayPal deposit request was canceled'; // should use translation service
                    this.isLoading = false;
                    this.hasReturnError = true;
                    this._resultError({ event: 'ERROR', messages: [errorMsg]});
                }
            },
            (err) => this._handlePayPalException(err)
        );
    }

    /**
     * This clears out the queryString from the current navigation
     *  state and replaces the top of the route stack.
     *
     *  We do this to prevent users from refreshing/navigating
     *  BACK to trigger additional calls
     */
    /*private _clearPayPalQueryState() {
        const newpath = this._location.path(true).split('?')[0];
        this._location.replaceState(newpath, '');
    }*/

    private _handlePayPalException(error) {
        let errMsg = FullPageFundingConstants.DEFAULT_ERROR_MESSAGE;

        if (error.errorCode === this._paypalService._WRONG_PAYPAL_ACCOUNT_CODE) {
            // Wait Until funding details are complete so we can
            //  provide the proper error messaging.
            errMsg = FullPageFundingConstants.WRONG_PAYPAL_ACCOUNT_ERROR;
            this._accountInfoService.requestAccountInfo({enabledFundIDs: [FUND_ID.PAYPAL], type: FUNDING_OPERATIONS.DEPOSIT}).pipe(take(1)).subscribe((accountInfo) => {
                this.paypalAccount = accountInfo.fundingOptions[0].accountInfo;
                this.isLoading = false;
                this.hasError = true;
                this.hasReturnError = true;
                this._resultError({event: 'ERROR', messages: [errMsg], code: error.errorCode});
            },
                (err) => this._resultError({event: 'ERROR', messages: [errMsg]})
            );
        } else {
            if (error.errorCode === this._paypalService._TOTE_DOWN_CODE) {
                errMsg = FullPageFundingConstants.TOTE_DOWN_ERROR;
                this.hasToteError = true;
                setTimeout(() => {
                    if (this.betShare) {
                        this.fpd_done_close();
                    } else {
                        this.close();
                    }
                }, 5000);
            }
            this.isLoading = false;
            this.hasError = true;
            this.hasReturnError = true;
            this._resultError({ event: 'ERROR', messages: [errMsg] });
        }
    }

    private _completeMazoomaTransaction(orderId: string) {
        const amount = this._ezBankService.getStoredDepositAmount(orderId);
        this._fundingService.postDepositRedirectURL = sessionStorage.getItem('postDepositRedirectURL');
        this._ezBankService.getTransactionStatus(orderId)
            .pipe(
                take(1),
                /* moving this up from iframe component has introduced a race condition with
                 * selectComponent function called from deposit-options component, so introducing a slight delay
                 * so error message shows, otherwise, selectComponent function clears postDeposit flag
                 */
                delay(this._delayTime)
            )
            .subscribe((status: IEZBankTransactionStatus) => {
                // handle SUCCESS/FAILURE responses, (and ignore "Pending" responses)
                // initialize some common variables
                let pageviewStatus = EZBankPageviewStatus.FAILURE;

                if (status.transactionErrorCode !== null) {
                    if (status.transactionErrorCode === '99') {
                        // update pageview status to "CANCELLED"
                        pageviewStatus = EZBankPageviewStatus.CANCELLED
                    }
                    // EZBank cancelled. Update funding service to reflect error.
                    this._fundingService.ezBankErrorCode = status.transactionErrorCode;
                    this._ezBankService.logFailureEvent(this._fundingService.ezBankErrorCode, amount);
                } else if (status.transactionStatus === enumEZBankTransactionStatus.TOTEFAILURE) {
                    // EZBank deposit succeeded, but Tote balance update failed. Update funding service to reflect error.
                    this._fundingService.ezBankErrorCode = EzbankService.EZBANK_TOTE_FAILURE_CODE;
                    this._ezBankService.logFailureEvent(this._fundingService.ezBankErrorCode, amount);
                } else {
                    // update pageview status to "SUCCESS"
                    pageviewStatus = EZBankPageviewStatus.SUCCESS;
                }

                // redirect to success or ezbank widget and indicate success or failure
                if (pageviewStatus === EZBankPageviewStatus.SUCCESS) {
                    this._resolveMazoomaTransaction(amount);
                } else {
                    this._resolveMazoomaTransaction(amount);
                }
            },
            // same error logic as ezBank iFrame component
            _ => {
                // resolve loader, redirect to ezbank widget, and indicate "unknown error"
                this._fundingService.ezBankErrorCode = EzbankService.unknownErrorCode;
                this._ezBankService.logFailureEvent(this._fundingService.ezBankErrorCode, amount.toString());
                this._resolveMazoomaTransaction(amount);
            });
    }

    private _resolveMazoomaTransaction(depositAmount: string) {
        this._loadingService.resolve(FullPageFundingComponent._OVERLAY_NAME, 0).then(() => {
            this._fundingService.updateAccountBalance().pipe(
                take(1),
                finalize(() => {
                    // Show success or error state based on result from mazooma
                    // Call parent method to set error message if there is one
                    if (typeof this._fundingService.ezBankErrorCode !== 'undefined' && this._fundingService.ezBankErrorCode !== null) {
                        const errorCode = 'ezbank-' + this._fundingService.ezBankErrorCode;
                        this._fundingService.ezBankErrorCode = null;
                        this.errorMessage = this._translateService.translate(errorCode, 'errorcodes', true);
                        this.hasReturnError = true;
                        this.isLoading = false;
                    }

                    this._fundingService.onFundingEvent.emit({
                        event: this.errorMessage ? 'ERROR' : 'SUCCESS',
                        messages: [this.errorMessage || `${this._currencyPipe.transform(depositAmount, 'USD', 'symbol-narrow')} has been deposited to your account with Instant Bank Transfer.`],
                        amount: +depositAmount
                    });
                })
            ).subscribe();
        });
    }

    private _titleCaseWord(word: string) {
        if (!word) {
            return word;
        }
        if (word.length === 1) {
            return word.toUpperCase();
        }
        return word[0].toUpperCase() + word.slice(1);
    }

    public addNewCard() {
        this.clearErrors();

        if (this.selectedFundId === FUND_ID.CREDITCARD) {
            const option = this._loadedFundingMethods.find(element => element.fundId === this.selectedFundId);
            this._depositOptions.replaceFundingMethod(option);
        }

        this._changeDetectorRef.markForCheck();
    }

    public methodsLoaded($event: IFundingOption[]) {
        this._loadedFundingMethods = $event;
    }

    public goToOptions(): void {
        this._router.navigate(['funding', this.selectedOperation, 'options']);
    }
}
