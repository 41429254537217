import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CARD_TYPES, FUNDING_OPERATIONS, FUND_ID, IFundingOption } from '@cdux/ng-common';
import { FundingType } from '@cdux/ng-fragments';

import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';

const PREFIX = 'cdux-funding-option';
const MASTER = 'mc';
const VISA = 'visa';
const DISCOVER = 'disc';
const AMEX = 'amex';

let NEXT_UNIQUE_ID = 0;

@Component({
  selector: 'cdux-funding-option',
  templateUrl: './funding-option.component.html',
  styleUrls: [ './funding-option.component.scss' ]
})
export class FundingOptionComponent implements OnInit {

    @Input() public displayStyle: enumFundingDisplayStyle = enumFundingDisplayStyle.SIDEBAR;

  // Which Funding Method is being used
  @Input() public fundingMethodDetails: IFundingOption | null;
  // Whehter it is in an Error state
  @Input() public hasError: boolean;
  // Whether it can be replaced
  @Input() public canReplace: boolean;
  // Whether to hide account details section
  @Input() public hideAccountDetails: boolean;
  // Icon to Display
  @Input() public icon: string;
  // cardtype detection
  @Input() public cardType: string;
  // Is Amex credit enabled on this affiliate
  @Input() public amexEnabled: boolean;
  // Is option deposit or withdraw
  @Input() public fundingOperation;
    // Disable funding option
  @Input() public disabled: boolean;

  // Event Emitted on Click
  @Output() public select: EventEmitter<IFundingOption> = new EventEmitter();
  // Event Emitted to Remove Account Info
  @Output() public replace: EventEmitter<IFundingOption> = new EventEmitter();

  @Input() set id(value: string) {
    value = PREFIX + value;
    if (this._id !== value) {
      this._id = value;
    }
  }
  get id(): string {
    return this._id;
  }

    // expose enum to template
    public enumDepositDisplayStyle = enumFundingDisplayStyle;
    public FundingMethodTypes = FUND_ID;
    public CreditCardTypes = CARD_TYPES;
    public isCardTypeAmex: boolean = false;

    public withdrawOptionClassName: string[];
    public withdrawMethodFee: string;
    public withdrawMethodDeliveryTime: string;

    private _id = `${PREFIX}-${NEXT_UNIQUE_ID++}`;

    ngOnInit() {
        this.isCardTypeAmex = false;
        if (this.fundingMethodDetails  && this.fundingMethodDetails.fundId === FUND_ID.CREDITCARD && this.fundingMethodDetails.accountInfo) {
            switch (this.fundingMethodDetails.cardTypeID) {
                case CARD_TYPES.MC:
                    this.cardType = MASTER;
                    break;
                case CARD_TYPES.DISC:
                    this.cardType = DISCOVER;
                    break;
                case CARD_TYPES.AMEX:
                    this.cardType = AMEX;
                    this.isCardTypeAmex = true;
                    break;
                case CARD_TYPES.VISA:
                default:
                    this.cardType = VISA;
                    break;
            }
        }
        this.withdrawMethodFee = this._getWithdrawMethodFee(this.fundingMethodDetails);
        this.withdrawMethodDeliveryTime = this._getWithdrawMethodDeliveryTime(this.fundingMethodDetails);
        this.withdrawOptionClassName = this._getWithdrawOptionClassName();
    }

    public getFundingClassName () {
        const fundingClassName = this.fundingMethodDetails.fundType.toLocaleLowerCase().replace(' ', '').replace('_w', '');
        const ezMoneyClassName = 'ezmoney';
        const updatedEzmoneyClassName = 'ach';
        if (fundingClassName === ezMoneyClassName) {
            return updatedEzmoneyClassName;
        }
        return fundingClassName === 'check' ? 'checkbymail' : fundingClassName;
    }

    public getFundingMethodFee() {
        const moneyGramFee: string = '$3.95 at Retailer';
        const payNearMeFee: string = '$3.99 at Retailer';
        const greenDotFee: string = '$4.95 at Retailer';
        const dollarPrefix: string = '$';
        const fee: string = ' ' + 'Fee';

        if (this.fundingMethodDetails.flatFee === 0 ) {
            if (this.fundingMethodDetails.fundType === 'MONEYGRAM') {
                return moneyGramFee;
            }
            else if (this.fundingMethodDetails.fundType === 'PAYNEARME') {
                return payNearMeFee;
            }
            else if (this.fundingMethodDetails.fundType === 'GREENDOTATR') {
                return greenDotFee;
            }
        }
        else {
            if (this.fundingMethodDetails.flatFee.toString().length === 1) {
                return dollarPrefix + this.fundingMethodDetails.flatFee + fee;
            } else {
                return dollarPrefix + this.fundingMethodDetails.flatFee.toFixed(2) + fee;
            }
        }
    }

  /**
   * Retrieves the Suggestion Amounts from the backend and passes them into the Funding Option Details component to be injected into the Sidenav
   */
  public onMethodClick() {
     if(!this.disabled) {
        this.select.emit(this.fundingMethodDetails);
     }
  }

  /**
   * Triggers the Business Service to remove the Account Info associated to the associated Funding Method
   * @param event - Event to prevent the onMethodClick Event from firing
   */
  public onReplaceClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.replace.emit(this.fundingMethodDetails);
  }

  /**
   * Determine whether or not the replace text should be shown
   */
  public showReplaceLink() {
    // If funding operation is present, verify that it is set to deposit. If it's not there, bypass that check.
    return (!this.fundingOperation || this.fundingOperation === FUNDING_OPERATIONS.DEPOSIT) && this.canReplace && this.fundingMethodDetails.accountInfo;
  }

  public showBulletIcon() {
    return this.fundingMethodDetails.fundCode === 'EZMONEY' || this.fundingMethodDetails.fundCode === 'CREDITCARD';
  }

  private _getWithdrawOptionClassName(): string[] {
    const classes = [
        (this.fundingMethodDetails.fundId === this.FundingMethodTypes.CREDITCARD) ? this.cardType : this.fundingMethodDetails.fundType?.toLowerCase(),
        this.disabled ? 'disabled' : '',
        (this.fundingMethodDetails.fundId === this.FundingMethodTypes.PAYPAL || this.fundingMethodDetails.fundId === this.FundingMethodTypes.PAYPAL_W) ? 'paypal' : '',
        (this.fundingMethodDetails.fundId === this.FundingMethodTypes.CHECK || this.fundingMethodDetails.fundId === this.FundingMethodTypes.CHECK_W) ? 'checkbymail' : ''
    ];
    return classes.filter(className => className !== '');
  }

  private _getWithdrawMethodFee(fundingMethod: IFundingOption) {
        const ezMoneyFee: string = 'No Fees';
        const paypalFee: string = `$${this.fundingMethodDetails.flatFee} Fee`;
        const checkByMailFee: string = 'Fees Vary';

        switch (fundingMethod.fundType) {
            case FundingType.EZ_MONEY.toUpperCase() || FundingType.EZ_MONEY_W.toUpperCase():
                return ezMoneyFee;
            case FundingType.PAY_PAL.toUpperCase() || FundingType.PAY_PAL_W.toUpperCase():
                return paypalFee;
            case FundingType.CHECK.toUpperCase() || FundingType.CHECK_W.toUpperCase():
                return checkByMailFee;
            default:
                return
        }
  }

  private _getWithdrawMethodDeliveryTime(fundingMethod: IFundingOption) {
        const ezMoneyTime: string = '1-4 Days';
        const paypalTime: string = '2-5 Days';
        const checkByMailTime: string = 'Up to 14 Business Days';

        switch (fundingMethod.fundType) {
            case FundingType.EZ_MONEY.toUpperCase() || FundingType.EZ_MONEY_W.toUpperCase():
                return ezMoneyTime;
            case FundingType.PAY_PAL.toUpperCase() || FundingType.PAY_PAL_W.toUpperCase():
                return  paypalTime;
            case FundingType.CHECK.toUpperCase() || FundingType.CHECK_W.toUpperCase():
                return checkByMailTime;
            default:
                return
        }
  }
}
