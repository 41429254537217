<div class="classic-card" id="content-card-{{ index }}">
    <img *ngIf="card.imageUrl" [src]="card.imageUrl" (click)="onCardClick()" class="image" alt="card?.description">
    <div class="card-content" [ngStyle]="card.imageUrl ? null : {'margin-left': '10px'}">
      <div class="title">{{ card.title }}</div>
      <div class="description">{{ card.description }}</div>
      <a href="{{ card.url }}"><div class="link">{{ card.linkText }}</div></a>
      <div class="notes" *ngIf="card.pinned">*Pinned Inbox message is not dismissible.</div>
    </div>

</div>
