import { Input, Optional, ChangeDetectorRef, Directive } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import { IWithdrawBalance, ICheckAccountInfo, FUNDING_OPERATIONS, TranslateService, JwtSessionService, FeatureToggleDataService } from '@cdux/ng-common';

import { FundingService } from '../../shared/services/funding.service';

import { FundingAbstractMethodComponent } from './abstract-method.component';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { CduxSidebarContentComponent } from '../../../sidebar/cdux-sidebar-content-component.class';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service'
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../sidebar/interfaces/sidebar-portal-component.interface';
import { ComponentType } from '@angular/cdk/portal';
import { IFundingMethodComponentProperties } from '../../shared/interfaces/funding-sidebar-component.interfaces';
import { Router } from '@angular/router';
import { HeaderService } from 'app/shared/header/services/header.service';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';


const ACCOUNT_INFO_TOKEN = 'accountInfo';
const WITHDRAW_BALANCE_TOKEN = 'withdrawBalance';

@Directive()
export abstract class FundingAbstractMethodWithdrawComponent extends FundingAbstractMethodComponent {

  @Input() public accountInfo: ICheckAccountInfo | null;
  @Input() public withdrawBalance: IWithdrawBalance | null;

  public displayActionItem: boolean

    protected static createSidebarPortal(component: ComponentType<CduxSidebarContentComponent>, options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        const portalComponent: ISidebarPortalComponent = super.createSidebarPortal(component, options);

        if (options.accountInfo) {
            portalComponent.properties.inputs.set(ACCOUNT_INFO_TOKEN, options.accountInfo);
        }

        if (options.withdrawBalance) {
            portalComponent.properties.inputs.set(WITHDRAW_BALANCE_TOKEN, options.withdrawBalance);
        }

        return portalComponent;
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);

        if (properties && properties.inputs) {
            if (properties.inputs.has(ACCOUNT_INFO_TOKEN)) {
                this.accountInfo = properties.inputs.get(ACCOUNT_INFO_TOKEN);
            }

            if (properties.inputs.has(WITHDRAW_BALANCE_TOKEN)) {
                this.withdrawBalance = properties.inputs.get(WITHDRAW_BALANCE_TOKEN);
            }
        }
    }

    public isSmallGlass: boolean = false;

    constructor(
    _environment: ENVIRONMENT,
    _fb: UntypedFormBuilder,
    _fundingService: FundingService,
    _sidebarService: SidebarService,
    _eventTrackingService: EventTrackingService,
    _translateService: TranslateService,
    public router: Router,
    private _headerService: HeaderService,
    private _mediaService: CduxMediaToggleService,
    protected _sessionService: JwtSessionService,
    _featureToggleService: FeatureToggleDataService,
    @Optional() _cdr?: ChangeDetectorRef
  ) {
    super(
        _environment,
        _sidebarService,
        _fb,
        _fundingService,
        _eventTrackingService,
        _translateService,
        _featureToggleService,
        _cdr
    );
    this.operation = FUNDING_OPERATIONS.WITHDRAW;
    this.isSmallGlass = this._mediaService.query('phone');
  }

  public getMaxWithdrawAmount() {
      const max = this.withdrawBalance ? this.withdrawBalance.availableBalance : 1000;
      return this.fundingMethodDetails.flatFee ? max - this.fundingMethodDetails.flatFee : max;
  }

  public navigateToOptions() {
    this.router.navigate(['funding', 'withdraw', 'options']);
  }

  public close() {
    let redirectUrl = '/';
    if (this._sessionService.redirectLoggedInUserUrl
        && this._sessionService.redirectLoggedInUserUrl.split('/')[1] !== this.router.url.split('/')[1]) {  // Check to ensure the previous location wasn't the deposit page
        redirectUrl = this._sessionService.redirectLoggedInUserUrl;
    } else if (this._fundingService.postDepositRedirectURL) {
        redirectUrl = this._fundingService.postDepositRedirectURL;
    }
    // Tell the header service that this is reverse navigation.
    this._headerService.reverseNavigation = true;

    this.router.navigate(
        redirectUrl.replace(/\?.*/, '').split('/'),
        {
            queryParams: redirectUrl.replace(/[^?]*\??/, '').split('&').reduce((params, kv) => {
                const [left, right] = kv.split('=');
                params[left] = right;
                return params;
            }, {}),
            state: {
                returningBetId: this.inboundBet
            }
        }
    );
  }

}
