import { Directive, ElementRef, Output, EventEmitter, OnInit, OnDestroy, Input } from '@angular/core';

@Directive({
    selector: '[cduxInboxVisible]'
})
export class CduxInboxVisibleDirective implements OnInit, OnDestroy {
    @Input() visibilityTopMargin: number | string = 0;
    @Input() scrollContainer: HTMLElement;
    @Output() isVisible = new EventEmitter<boolean>();

    private observer: IntersectionObserver;

    constructor(private element: ElementRef) {}

    ngOnInit() {
        if (!this.scrollContainer) {
            console.warn('Scroll container not provided to cduxVisible directive. Defaulting to viewport.');
        }

        const rootMargin = this.parseRootMargin(this.visibilityTopMargin);

        const options = {
            root: this.scrollContainer || null,
            rootMargin: rootMargin,
            threshold: 0.5
        };

        this.observer = new IntersectionObserver(([entry]) => {
            this.isVisible.emit(entry.isIntersecting);
        }, options);

        this.observer.observe(this.element.nativeElement);
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    private parseRootMargin(margin: number | string): string {
        if (typeof margin === 'number') {
            return `${margin}px 0px 0px 0px`;
        } else if (typeof margin === 'string') {
            // Check if the string already ends with px or %
            if (margin.endsWith('px') || margin.endsWith('%')) {
                return `${margin} 0px 0px 0px`;
            } else {
                // Assume it's a number without units and add px
                return `${margin}px 0px 0px 0px`;
            }
        }
        // Default to 0px if an invalid value is provided
        return '0px 0px 0px 0px';
    }
}
