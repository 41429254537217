import { EventClickType, FeatureToggleDataService, JwtSessionService } from '@cdux/ng-common';
import { Component } from '@angular/core';

import { FundingDepositOptionsComponent } from '../../funding/components/deposit-options/deposit-options.component';
import { FundingWithdrawOptionsComponent } from '../../funding/components/withdraw-options/withdraw-options.component';

import { SsnCollectionSidebarComponent } from '../../ssn-collection/ssn-collection-sidebar.component';
import { CduxSidebarContentComponent } from '../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from '../../sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from '../../sidebar/sidebar.service';
import { MenuItemsEnum } from '../../menu-items/enums/menu-items.enum';
import { SIDEBAR_LOADERS } from '../../sidebar/enums/loader.enums';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { Router } from '@angular/router';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Component({
    selector: 'cdux-cashier',
    templateUrl: './cashier.component.html',
    styleUrls: ['./cashier.component.scss']
})
export class CashierComponent extends CduxSidebarContentComponent {

    public ftFullPageDeposit = false;
    public tournamentSelected = false;
    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */
    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: CashierComponent,
            properties: {
                navTarget: MenuItemsEnum.PROFILE
            }
        };
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            translateKey: 'cashier-title',
            translateLanguage: 'my-account',
            eventClickType: EventClickType.MY_ACCOUNT_MY_FUNDS_BACK_BUTTONS
        }
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        private sessionService: JwtSessionService,
        private sidebarService: SidebarService,
        private _fundingService: FundingService,
        private _router: Router,
        private _tournamentsSession: TournamentsSessionService,
        localFeatureToggleService: FeatureToggleDataService,
    ) {
        super();
        this.ftFullPageDeposit = localFeatureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);

        this._tournamentsSession.onTournamentSelection.subscribe(
           (res) => {
             this.tournamentSelected = res ? true : false;
           }
        );
    }

    public loadDeposit() {
        if (this.ftFullPageDeposit) {
            this._fundingService.postDepositRedirectURL = this._router.url.split('?')[0];

            this._router.navigate(['/deposit'],
                // used to prioritize Online Banking (EzMoney) & prevent last used logic. see FUND-23866
                {state: {preventLastUsedLogic: true}}
                );
        } else {
            this.sidebarService.loadComponent(FundingDepositOptionsComponent.getSidebarComponent(), FundingDepositOptionsComponent.getHeaderComponent());
        }
    }

    public loadWithdraw() {
        this.sidebarService.loadComponent(FundingWithdrawOptionsComponent.getSidebarComponent(), FundingWithdrawOptionsComponent.getHeaderComponent()).then(() => {
            /*
            * Check if the user has a taxable ("frozen") wager and an
            * incomplete SSN. If so, present the user with SSN Collection
            * before showing withdraw options (ie, going "back" to withdraw).
            */
           const userInfo = this.sessionService.getUserInfo();
            if (userInfo.frozenWager && userInfo.cssdLength < 9) {
                this.sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.DOT_LOADER);
                this.sidebarService.loadComponent(SsnCollectionSidebarComponent.getSidebarComponent(), SsnCollectionSidebarComponent.getHeaderComponent());
            }
        });
    }

}
